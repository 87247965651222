import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useUiStore, Modals, Drawers } from '../../stores/uiStore'
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { styled } from 'styled-components'
import Drawer from './DrawerWrapper'
import { Avatar, FlexboxGrid, List, RadioTile, RadioTileGroup } from 'rsuite'
import { COLORS } from '../../config/colors'
import { Paragraph, ParagraphBold, Title } from '../Typography'
import TextInput from '../Inputs/TextInput'
import { device } from '../../config/devices'
import Button from '../Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { useAuthPresenter } from '../../pages/front/presenter';
import { useUserStore } from '../../stores/userStore';
import { logo } from '../../images';
import { FaSafari, FaUserCog, FaUserEdit } from 'react-icons/fa';
import { MdOutlineDashboard } from 'react-icons/md';
const MenuMobile: React.FC = observer(() => {
    const ui = useUiStore()
    const { setDrawer } = ui
    const { user } = useUserStore()
    return (
        <>
            <Drawer
                isOpened={ui[Drawers.MENU_MOBILE_DRAWER]}
                onClose={() => {
                    setDrawer(Drawers.MENU_MOBILE_DRAWER, false)
                }}
                loadding={false}
                onSubmit={() => { }}>
                <Container>
                    {user? 
                    <>
                    <List hover>
                      <List.Item>
                      <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={4}>
                          <Avatar src={user.img_url} circle />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={16}>
                          <ParagraphBold style={{margin:0, padding:0, textAlign:"right", marginRight:10}}>
                            {user.name}
                          </ParagraphBold>
                          <Paragraph color='#666' style={{margin:0, padding:0, textAlign:"right", marginRight:10}}>
                            {user.email}
                          </Paragraph>
                        </FlexboxGrid.Item>
                       </FlexboxGrid>

                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/user/meu-percurso`}>
                        <ParagraphBold>Meu percurso</ParagraphBold>
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/instrutor`}>
                        <ParagraphBold><MdOutlineDashboard size={16} />{"  "}Painel de instrutor</ParagraphBold>
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/instute`}>
                        <ParagraphBold> <FaSafari size={16} />{"  "} Painel de instituição</ParagraphBold>
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/user/confi`}>
                        <ParagraphBold><FaUserCog size={18} />{"  "} Configurações de conta</ParagraphBold>
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/user/editar-profile`}>
                        <ParagraphBold><FaUserEdit size={18} />{"  "} Editar perfil</ParagraphBold>
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={`/dashboard/user/editar-profile`}>
                        <ParagraphBold><FaUserLarge size={16} />{"  "}Terminar sessão</ParagraphBold>
                        </Link>
                      </List.Item>
                    </List>
                    </>:<>
                    <Logo src={logo} /> 
                    <Paragraph style={{marginTop:10}}>
                        Caso tenha uma conta faça login, o contrario inscreva-se .
                    </Paragraph>
                    <Button 
                      label='Inscrever-se'
                      onClick={() => {
                        setDrawer(Drawers.SIGNUP_DRAWER, true)
                      }}   
                      width='100' 
                      color='#000'
                    />
                    <Button 
                      label='Entrar agora' 
                      onClick={() => {
                        setDrawer(Drawers.LOGIN_DRAWER, true)
                      }}   
                      width='100' 
                      color='#000'
                    />
                    </>}
                </Container>
            </Drawer>
        </>)

});
export default MenuMobile;
const Container = styled.div`
  width: 100%;
   @media ${device.mobileL} {
    position:absolute;
    width:58%;margin-left:3%;
    left:0;  top:60px;
  }
`
const Logo = styled.img`
height:30px;margin 0 auto;
`