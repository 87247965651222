import React from 'react'
import { observer } from 'mobx-react-lite'
import { Uploader } from 'rsuite'
import { useUiStore, Modals, Drawers } from '../../stores/uiStore'
import {
    FaAccusoft,
    FaAddressCard,
    FaBook,
    FaCamera,
    FaDesktop,
    FaLock,
    FaMobileAlt,
    FaPencilAlt,
    FaPlus,
    FaPlusCircle,
    FaServer,
    FaTrash,
    FaUser,
    FaUsers
} from 'react-icons/fa'
import { styled } from 'styled-components'
import Drawer from './DrawerWrapper'
import { RadioTile, RadioTileGroup } from 'rsuite'
import { COLORS } from '../../config/colors'
import { Paragraph, ParagraphBold, Title } from '../Typography'
import TextInput from '../Inputs/TextInput'
import { device } from '../../config/devices'
import { Link } from 'react-router-dom'
import { FaUserLarge } from 'react-icons/fa6'
import Button from '../Buttons/Button'
import { logo } from '../../images'
import { useAuthPresenter } from '../../pages/front/presenter'
import theme from '../../theme'
const SignUp: React.FC = observer(() => {
    const ui = useUiStore()
    const { setDrawer } = ui
    const { setFormValue, signUp, loading } = useAuthPresenter()
    return (
        <>
            <Drawer
                isOpened={ui[Drawers.SIGNUP_DRAWER]}
                onClose={() => {
                    setDrawer(Drawers.SIGNUP_DRAWER, false)
                }}
                loadding={false}
                onSubmit={() => { }}>
                <Container>
                    <Logo src={logo} />
                    <Title>Criar uma nova conta .</Title>
                    <Paragraph style={{marginTop:7}} size="14px" color='#000'>Estude a hora que quiser com  centros de formação e profissionais autónomos .</Paragraph>
                    <TextInput 
                    placeholder='Nome Completo :' 
                    name='name'
                    icon={<FaUserLarge style={{ padding: 7 }} />}
                    onChange={setFormValue}
                    />
                    <TextInput 
                    placeholder='Email :' 
                    name='email'
                    icon={<FaUserLarge style={{ padding: 7 }} />}
                    onChange={setFormValue}
                    />
                    <TextInput 
                    type="password" 
                    name='password'
                    placeholder='Palavra Passe :' 
                    icon={<FaLock style={{ padding: 7 }} />} 
                    onChange={setFormValue}
                    />
                    <TextInput 
                    type="password" 
                    name='confirm_password'
                    placeholder='Rescrever Palavra Passe :' 
                    icon={<FaLock style={{ padding: 7 }} />} 
                    onChange={setFormValue}
                    />
                    <Button
                        label='INSCREVER-SE GRATUITAMENTE'
                        color={theme.BACKGROUND.BLACK}
                        loading={loading}
                        onClick={signUp}
                    />
                    <Paragraph  style={{ marginTop: 10, textAlign: 'center' }}>
                        Já tem uma conta  na  <strong><Link to={``}>Rosa Academy</Link></strong> ?
                        <strong>
                            <Link to={``}>{"  "} Entrar agora</Link>
                        </strong> .
                    </Paragraph>
                </Container>

            </Drawer>
        </>)

});
export default SignUp;
const Container = styled.div`
  width: 100%;
   margin-top:50px;
   @media ${device.mobileL} {
    position:absolute;
    width:58%;margin-left:3%;
    left:0;  top:60px;
    margin-top:20px;
  }
`
const Logo = styled.img`
height:40px;margin-bottom:10px;
@media ${device.mobileL} {
 height:30px;
}
`