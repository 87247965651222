import { observer } from 'mobx-react-lite'
import { FaHouseUser, FaReact, FaRegMoneyBillAlt, FaRocket, FaUserGraduate } from 'react-icons/fa'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { FaBookmark, FaFacebook, FaLink, FaLinkedin, FaRegBookmark, FaUserInjured, FaUserLarge, FaUserPen, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import Select from "react-select";
import React, { useRef, useState } from 'react'
import { Avatar, Col, InlineEdit, Input, Row, Tabs } from 'rsuite';
import { Body, ButtonGroup, CardBody, Container, Content } from '../../style';
import { Paragraph, ParagraphBold } from '../../../../components/Typography';
import TextInputLarge from '../../../../components/Inputs/TextInputLarge';
import { useUserStore } from '../../../../stores/userStore';
import styled from 'styled-components';
import Button from '../../../../components/Buttons/Button';
import theme from '../../../../theme';
import { useUserPresenter } from '../presenter'
import { useDebounceEffect } from '../../../../components/canva/useDebounceEffect';
import { canvasPreview } from '../../../../components/canva/previewCanva';
import { MdEmail, MdLock, MdLockPerson, MdMailLock } from 'react-icons/md'

const ConfigCount: React.FC = observer(() => {
    const { imgSrc, setImgSrc, loadding, uploadImageProfile } = useUserPresenter()
    const { user } = useUserPresenter()
    return (
        <Container>
            <Content>
                <CardBody>
                    <Body>
                        <Tabs defaultActiveKey="1" vertical>
                            <Tabs.Tab eventKey="1" title="Alterar email">
                                <FormWrapper>
                                    <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
                                        <MdMailLock size={24} />{"  "} Alterar email de acesso.
                                    </ParagraphBold>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>Email</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.email}
                                                placeholder='Email :'
                                                icon={<MdEmail size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ParagraphBold>Palavra passe</ParagraphBold>
                                            <TextInputLarge
                                                placeholder='Palavra passe :'
                                                type='password'
                                                icon={<MdLock size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                    </Row>
                                    <ButtonGroup>
                                        <Button label="Salvar Informações" width='30' color={theme.BACKGROUND.BLUE} />
                                    </ButtonGroup>
                                </FormWrapper>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="3" title="Alterar palavra passe">
                                <FormWrapper>
                                    <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
                                        <MdLockPerson size={26} />{"  "} Alterar palavra passe.
                                    </ParagraphBold>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>Palavra passe actual</ParagraphBold>
                                            <TextInputLarge
                                                type="password"
                                                placeholder='Digite palavra passe actual :'
                                                icon={<MdEmail size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ParagraphBold>Nova palavra passe</ParagraphBold>
                                            <TextInputLarge
                                                placeholder='Digite a nova palavra passe :'
                                                icon={<MdLock size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>Confirmar nova palavra passe</ParagraphBold>
                                            <TextInputLarge
                                                placeholder='Confirme a nova palavra passe :'
                                                icon={<MdLock size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ButtonGroup style={{ marginTop: 24 }}>
                                                <Button label="Salvar Informações" width='100' color={theme.BACKGROUND.BLUE} />
                                            </ButtonGroup>
                                        </Col>
                                    </Row>

                                </FormWrapper>
                            </Tabs.Tab>
                        </Tabs>
                    </Body>
                </CardBody>
            </Content>
        </Container>
    )
})

export default ConfigCount

const FormWrapper = styled.div`
 margin:2% 2%   
`