import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import { H1, Paragraph, ParagraphBold} from '../../../components/Typography'
import styled from 'styled-components'
import { bg, bg10, bg11, bg7, bg8, bg9, instrutor, main } from '../../../images'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Modals as Modal, useUiStore } from '../../../stores/uiStore'
import { FaBriefcase, FaChalkboardTeacher, FaChartPie, FaDesktop, FaDraftingCompass, FaMoneyBillAlt, FaRocket, FaServer, FaUserGraduate, FaUserTie } from 'react-icons/fa'
import { ButtonPayCourse, CardCourse, CardImage, ContentCourse, ImageCardCourse, InfoCardCourse, Section } from '../style'
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Col, Divider, Grid, Rate, Row } from 'rsuite'
import { FaCartShopping, FaCheck, FaComputer, FaGenderless, FaPlay } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/Buttons/Button'
import { Image, ImageMain, PlayVideo, PlayVideoButton, TextMargin, Title } from '../training-center'

const Company: React.FC = observer(() => {
    const { courseList } = useCoursePresenter();
    const navigate = useNavigate()
    return (
        <>
            <FrontLayout>
                <Section style={{margin:0, padding:0}}>
                    <Row>
                         <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} xsHidden>
                        <ImageMain backgroundImage={bg7}/>
                        </Col>
                        <Col lg={12} xl={12} xxl={24} md={24} sm={24}  style={{padding:10}}>
                        <TextMargin>
                            <Title  color='#333'>
                            Mantenha seus funcionários engajados e ajude-os a crescer.
                             </Title>
                             <Paragraph size="20px" style={{marginTop:15}}>
                             Crie uma cultura de aprendizado na sua organização com um amplo acervo de cursos sob demanda prontos para sua equipe.
                             </Paragraph>
                             <Paragraph size="18px">
                                <FaCheck color={theme.BACKGROUND.BLUE}/> Vamos trabalhar com você para desenvolver e implementar planos para melhorar o desenvolvimento de habilidades e a retenção dos funcionários;
                             </Paragraph>
                             <Paragraph size="18px">
                                <FaCheck color={theme.BACKGROUND.BLUE}/> Quando você escolhe um parceiro de aprendizado com soluções integradas para atender suas necessidades, você poderá deixar de gerenciar vários provedores de aprendizado e economizar muito tempo;
                             </Paragraph>
                            <Button label='FALE CONOSCO' width='100' color={theme.BACKGROUND.BLACK}/>
                        </TextMargin>
                        </Col>
                    </Row>
                </Section>
                <Section style={{backgroundColor:"#fff"}}>
                 <Grid fluid style={{padding:10}}>
                 <Row className="show-grid">
                    <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                    <PlayVideo>
                        <br/>
                        <PlayVideoButton>
                            <FaPlay color='#fff' size={20} style={{margin:"auto"}}/>
                        </PlayVideoButton>
                    </PlayVideo>
                    </Col>
                    <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{textAlign:"left", marginTop:20}}>
                    <Title style={{lineHeight:1}}>
                    Explore nossas soluções de aprendizado integradas para todos na sua organização.
                    </Title>
                    <Paragraph size="20px">
                    Fortaleça competências fundamentais em todas as suas equipes
                    </Paragraph>
                    <Paragraph size="17px">
                        <FaGenderless color={theme.BACKGROUND.BLUE}/> Crie equipes mais confiantes e produtivas;
                    </Paragraph>
                    <Paragraph size="17px">
                        <FaGenderless color={theme.BACKGROUND.BLUE}/> Apoie seus funcionários com mudança contínua;
                    </Paragraph>
                    <Paragraph size="17px">
                        <FaGenderless color={theme.BACKGROUND.BLUE}/> Inove ficando à frente de novas tecnologias;
                    </Paragraph>
                    <Paragraph size="17px">
                        <FaGenderless color={theme.BACKGROUND.BLUE}/> Aprendizado personalizado para toda sua equipe;
                    </Paragraph>
                    </Col>
                </Row>
                </Grid>
                </Section>
                <Section style={{padding:0}}>
                <Grid fluid style={{padding:10}}>
                <Row className="show-grid">
                    <Col lg={12} xl={14} xxl={12} md={24} xs={24} style={{textAlign:"left",  padding:10}}>
                        <Title  style={{ lineHeight:1}}>
                        Ofereça caminhos de desenvolvimento de competências selecionados, para que haja progressão pelos níveis Iniciante, Intermediário e Avançado.
                        </Title>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Fortaleça a empregabilidade na Rosa Academy ;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Explore uma variedade de recursos disponível para  impulsionar sua instituição;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ajude seus estudantes a aumentar a confiança no trabalho, aplicar o aprendido e aprimorar competências;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ofereça aos estudantes projetos práticos para treinar competências e se destacar diante dos empregadores;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Melhore as experiências de aprendizagem;
                        </Paragraph>
                    </Col>
                    <Col lg={12} xl={10} xxl={6}  style={{textAlign:"center"}}>
                        <Image src={bg8}/>
                    </Col>
                </Row>
                </Grid>
                </Section>
            </FrontLayout>
        </>
    )
})
export default Company;
interface IProps {
    backgroundImage?: string
}
