import { observer } from 'mobx-react-lite'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container, Content } from '../style'
import { Breadcrumb, Col, FlexboxGrid, Rate, Row, Tabs } from 'rsuite'
import styled from 'styled-components'
import React from 'react'
import { useCoursePresenter } from '../instrutor/cursos/presenter'
import { Scrollbars } from 'react-custom-scrollbars';
import { Paragraph, ParagraphBold } from '../../../components/Typography'
import theme from '../../../theme'
import { FaBars, FaPlay } from 'react-icons/fa6'
import Button from '../../../components/Buttons/Button'
const Watch: React.FC = observer(() => {
    const { id_course } = useParams();
    const { getCourseId, course, getModulesByCourseId, moduleList, getQtdAula } = useCoursePresenter()
    React.useEffect(() => {
        getCourseId(id_course as string);
        getModulesByCourseId(id_course as string)
    }, [])
    return (
        <>
            <Container>
                <Content >
                    <Row className="show-grid">
                        <Col sm={24} md={24} lg={15}>
                            <Player backgroundImage={course?.img_url} >
                                <ContentPlayer>
                                    <ButtonPlayer>
                                        <FaPlay color='#fff' size={28} />
                                    </ButtonPlayer>
                                </ContentPlayer>
                            </Player>
                            {course?.titulo &&
                            <>
                            <ParagraphBold size="22px">
                                {course?.titulo}
                            </ParagraphBold>
                            <ParagraphBold>
                                 <Rate color='yellow' readOnly defaultValue={4.5} allowHalf size='md' />
                            </ParagraphBold>
                            <Button color={theme.BACKGROUND.BLUE} width='50' label='ENVIAR UMA AVALIAÇÃO'/>
                            </>
                            }
                        </Col>
                        <Col sm={24} md={24} lg={9}>
                            <GradeCurricularPlayer>
                                <FlexboxGrid justify="start">
                                    <FlexboxGrid.Item colspan={2}>
                                        <FaBars size={25} style={{margin:13}}/>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={14}>
                                        <ParagraphBold size="14px" style={{ margin: 12 }}>
                                        Conteúdo curricular do curso
                                        </ParagraphBold>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                
                                <hr style={{margin:0,marginBottom:10}}/>
                                <Scrollbars style={{ width: "100%", height: 570 }}>
                                    {moduleList &&
                                        <>
                                            {
                                                moduleList.map((item, i) => (
                                                    <GridModulo>
                                                        <FlexboxGrid justify="space-between" style={{ margin: 8 }}>
                                                            <FlexboxGrid.Item colspan={12}>
                                                                <ParagraphBold>{i + 1} - {item.title}</ParagraphBold>
                                                            </FlexboxGrid.Item>
                                                            <FlexboxGrid.Item colspan={4}>
                                                                <Paragraph>
                                                                    0/{item.items_module?.length} {"   "} 00:00
                                                                </Paragraph>
                                                            </FlexboxGrid.Item>
                                                        </FlexboxGrid>
                                                        {item.items_module && i == 0 && <>
                                                            {item.items_module.map((item1, a) => (
                                                                <Link to={""} style={{ textDecoration: "none" }}>
                                                                    <GridItem>
                                                                        <FlexboxGrid justify="space-between" style={{ margin: 8 }}>
                                                                            <FlexboxGrid.Item colspan={14}>
                                                                                <ParagraphBold>{a + 1} - {item1.titulo}</ParagraphBold>
                                                                            </FlexboxGrid.Item>
                                                                            <FlexboxGrid.Item colspan={2}>
                                                                                <Paragraph>
                                                                                    00:00
                                                                                </Paragraph>
                                                                            </FlexboxGrid.Item>
                                                                        </FlexboxGrid>

                                                                    </GridItem>

                                                                </Link>
                                                            ))}
                                                        </>}
                                                    </GridModulo>
                                                )
                                                )
                                            }
                                        </>}
                                </Scrollbars>

                            </GradeCurricularPlayer>
                        </Col>
                    </Row>
                </Content>
            </Container >
        </>
    )
})

export default Watch
interface IProps {
    backgroundImage?: string
}
const Player = styled.div<IProps>`
  width:100%;
  height:480px;
  background: url(${({ backgroundImage }) => (backgroundImage ? backgroundImage : "#F4F4F4")});
  background-size: cover;
  margin-bottom:20px
`
const ContentTab = styled.div`
  width:100%;
  h1{
  font-family:Montserrat;
  }
`
const ContentPlayer = styled.div`
  width:100%;
  height:480px;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content:center;
  display:flex;
`
const ButtonPlayer = styled.button`
  width:70px;
  height:70px;
  border-radius:50%;
  margin:auto;
  background-color: ${theme.BACKGROUND.BLUE};
`
const GradeCurricularPlayer = styled.div`
  width:98%;
  background-color:#fff;
  border:1px solid #e9e9e9;
  padding-bottom:20px
`
const GridModulo = styled.div`
  width:96%;margin:0 auto;
  background-color:#f4f4f4;
  margin-bottom:10px;
  border:1px solid #e9e9e9;
  min-height:40px;
`
const GridItem = styled.div`
  width:96%;margin:0 auto;
  background-color:#fff;
  margin-bottom:7px;
  border:1px solid #e9e9e9;
  min-height:34px;
`