import React from "react";
import styled from "styled-components";

interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
    defaultValue?: string;
    type?: string;
    required?: boolean;
    placeholder?: string;
    width?: number;
    hasLabel?: boolean;
    label?: string;
    icon?: React.ReactNode
}
interface IProps {
    width?: number;
}

export default function TextInputModule({
    defaultValue = "",
    placeholder,
    width,
    hasLabel,
    label,
    icon,
    ...rest
}: ITextInput) {
    return (
        <Group width={width}>
            <Input defaultValue={defaultValue} placeholder={placeholder} {...rest} />
            {icon && icon}
        </Group>
    );
}

const Group = styled.div<IProps>`
  width: 100%;
  height:50px;background-color:#fff;
  border-color: #e9e9e9;
  border-style: solid;
  border-width: 1px;
  color: #333;
  font-family: inherit;
  font-size: 14px;
  margin-top:5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Input = styled.input`
 width:80%;
 border:0;
 margin-left:7px;font-family:Montserrat;
`;
