import { createBrowserHistory } from "history";
import { injectable } from "inversify";
import { makeAutoObservable } from "mobx";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";

@injectable()
class NavigationStore {
  history = createBrowserHistory();

  constructor() {
    makeAutoObservable(this);
  }

  push = (path: string, state?: any) => {
    this.history.push(path, state);
  };

  replace = (path: string, state?: any) => {
    this.history.replace(path, state);
  };

  go = (n: number) => {
    this.history.go(n);
  };

  goBack = () => {
    //this.history.goBack();
  };

  goForward = () => {
    //this.history.goForward();
  };
}

export const useNavigation = () =>
  useClassStore<NavigationStore>(container.get(NavigationStore));

export default NavigationStore;
