import { observer } from 'mobx-react-lite'
import { FaHouseUser, FaReact, FaRegMoneyBillAlt, FaRocket, FaUserGraduate } from 'react-icons/fa'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { FaBookmark, FaFacebook, FaLink, FaLinkedin, FaRegBookmark, FaUserInjured, FaUserLarge, FaUserPen, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import Select from "react-select";
import React, { useRef, useState } from 'react'
import { Avatar, Col, InlineEdit, Input, Row, Tabs } from 'rsuite';
import { Body, ButtonGroup, CardBody, Container, Content } from '../../style';
import { Paragraph, ParagraphBold } from '../../../../components/Typography';
import TextInputLarge from '../../../../components/Inputs/TextInputLarge';
import { useUserStore } from '../../../../stores/userStore';
import styled from 'styled-components';
import Button from '../../../../components/Buttons/Button';
import theme from '../../../../theme';
import { useUserPresenter } from '../presenter'
import { useDebounceEffect } from '../../../../components/canva/useDebounceEffect';
import { canvasPreview } from '../../../../components/canva/previewCanva';
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
const TO_RADIANS = Math.PI / 180
const EditarProfile: React.FC = observer(() => {
    const { imgSrc, setImgSrc, loadding, uploadImageProfile } = useUserPresenter()
    const { user } = useUserStore()
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [aspect] = useState<number | undefined>(10 / 10)
    const imgRef = useRef<HTMLImageElement>(null)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [crop, setCrop] = useState<Crop>()
    const [scale] = useState(1)
    const [rotate] = useState(0)

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }
    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }
    async function uploadImage() {
        const ctx = previewCanvasRef.current ? previewCanvasRef.current.getContext('2d') : null;
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        if (!ctx) {
            throw new Error('No 2d context')
        }
        const scaleX = image ? image?.naturalWidth / image?.width : null;
        const scaleY = image ? image?.naturalHeight / image?.height : null;
        const pixelRatio = window.devicePixelRatio;
        if (canvas && completedCrop && scaleX && scaleY && image) {
            canvas.width = Math.floor(completedCrop?.width * scaleX * pixelRatio);
            canvas.height = Math.floor(completedCrop?.height * scaleY * pixelRatio);

            ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'

            const cropX = completedCrop.x * scaleX
            const cropY = completedCrop.y * scaleY

            const rotateRads = rotate * TO_RADIANS
            const centerX = image.naturalWidth / 2
            const centerY = image.naturalHeight / 2
            ctx.save()

            // 5) Move the crop origin to the canvas origin (0,0)
            ctx.translate(-cropX, -cropY)
            // 4) Move the origin to the center of the original position
            ctx.translate(centerX, centerY)
            // 3) Rotate around the origin
            ctx.rotate(rotateRads)
            // 2) Scale the image
            ctx.scale(scale, scale)
            // 1) Move the center of the image to the origin (0,0)
            ctx.translate(-centerX, -centerY)
            ctx.drawImage(
                image,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
            )
            if (previewCanvasRef.current) {
                previewCanvasRef.current.toBlob(
                    (blob: any) => {
                        const newImage = new File([blob], blob.name, { type: blob.type, });
                        uploadImageProfile(newImage);
                    },
                    'image/jpg',
                    1
                )
            }
        }
    }
    return (
        <Container>
            <Content>
                <CardBody>
                    <Body>

                        <Tabs defaultActiveKey="1" vertical>
                            <Tabs.Tab eventKey="1" title="Perfil na Rosa Academy">
                                <FormWrapper>
                                    <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
                                        <FaUserPen size={26} />{"  "} Editar Informações de perfil
                                    </ParagraphBold>
                                    <ParagraphBold style={{ marginTop: 15 }}>Nome Completo :</ParagraphBold>
                                    <TextInputLarge
                                        defaultValue={user?.name}
                                        placeholder='Escrever nome completo :'
                                        icon={<FaUserLarge size={20} color='#999' style={{ margin: 14 }} />}
                                    />
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>Profissão ou título</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='Profissional ou título'
                                                icon={<FaUserInjured size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ParagraphBold>Site</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='URL'
                                                icon={<FaLink size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>Twitter</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='Nome de usuário :'
                                                icon={<FaXTwitter size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ParagraphBold>Facebook</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='Nome de usuário'
                                                icon={<FaFacebook size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold>LinkedIn</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='ID do recurso:'
                                                icon={<FaLinkedin size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <ParagraphBold>Youtube</ParagraphBold>
                                            <TextInputLarge
                                                defaultValue={user?.url}
                                                placeholder='Nome de usuário'
                                                icon={<FaYoutube size={20} color='#999' style={{ margin: 14 }} />}
                                            />
                                        </Col>
                                    </Row>
                                    <ParagraphBold>Biografia</ParagraphBold>
                                    <InlineEdit placeholder="Biografia :" style={{ marginBottom: 20, paddingTop: 7, fontFamily: "Montserrat", minHeight: 50, width: "100%", borderWidth: 1, borderStyle: "solid", borderColor: "#d3d3d3" }}>
                                        <Input as="textarea" rows={5} />
                                    </InlineEdit>
                                    <ButtonGroup>
                                        <Button label="Salvar Informações" width='30' color={theme.BACKGROUND.BLUE} />
                                    </ButtonGroup>
                                </FormWrapper>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="3" title="Foto de Perfil">
                                <FormWrapper>
                                    <Row className="show-grid" style={{ marginTop: 17 }}>
                                        <Col xs={12}>
                                            <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
                                                <FaUserPen size={26} />{"  "} Alterar foto de perfil
                                            </ParagraphBold>
                                            <Paragraph size="13">Faça o upload da imagem de perfil aqui. Ela deve atender aos nossos padrões de qualidade da imagem  para ser aceita. Diretrizes importantes: ter 750 x 422 pixels, estar no formato .jpg, .jpeg,. gif ou .png. e não ter nenhum texto na imagem.</Paragraph>
                                            <ParagraphBold>Carregar a foto de perfil</ParagraphBold>
                                            <ButtonUpload accept="image/*" onChange={onSelectFile} />
                                            <Button label='Selecionar imagem do curso ' />
                                        </Col>
                                        <Col xs={12}>
                                            <CardImgProfile>
                                                {imgSrc ?
                                                    <ReactCrop
                                                        crop={crop}
                                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                                        onComplete={(c) => {
                                                            setCompletedCrop(c)
                                                        }}
                                                        aspect={aspect}
                                                        minWidth={470}
                                                        minHeight={300}
                                                        circularCrop
                                                    >
                                                        <img
                                                            ref={imgRef}
                                                            alt="Crop me"
                                                            src={imgSrc}
                                                            onLoad={onImageLoad}
                                                        />
                                                    </ReactCrop> :
                                                    <ImageBackground>
                                                        {user?.img_url ? <Image src={user?.img_url} /> : <Avatar circle />}
                                                    </ImageBackground>}
                                            </CardImgProfile>
                                            {imgSrc && <Button label='Salvar imagem' onClick={uploadImage} loading={loadding} color={theme.BACKGROUND.BLUE} />}
                                            {!!completedCrop && (
                                                <>
                                                    <div>
                                                        <canvas
                                                            ref={previewCanvasRef}
                                                            id="canva"
                                                            style={{
                                                                border: '1px solid black',
                                                                objectFit: 'contain',
                                                                width: completedCrop.width,
                                                                height: completedCrop.height,
                                                                display: "none"
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </FormWrapper>
                            </Tabs.Tab>
                        </Tabs>
                    </Body>
                </CardBody>
            </Content>
        </Container>
    )
})

export default EditarProfile
const ButtonUpload = styled.input.attrs({ type: 'file' })`
appearance: none;
opacity: 0;width:100%;height:50px;
cursor: pointer;position:absolute;margin-top:10px;
    `
const CardImgProfile = styled.div`
width:95%;min-height:300px; 
padding:10px;       
`
const FormWrapper = styled.div`
 margin:2% 2%   
`
const ImageBackground = styled.div`
width:100%;min-height:300px; 
background-color:#f0f0f0;     
display: flex;
align-items: center;
justify-content: center;
`
const Image = styled.img`
width:70%;
border-radius:50%;margin:10px
`