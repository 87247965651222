import styled from "styled-components";
import { device } from "../config/devices";

interface IProps {
  center?: boolean;
  size?: string | number;
  font?: number;
}

export const Title = styled.h1<IProps>`
  font-style: normal;
  color: ${({ color }) => (color ? color : "#000")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 16px;
  font-family:Montserrat;
  font-size: ${({ size }) => size || 20}px;
   @media ${device.mobileL} {
    font-size:18px
  }
`;
export const H5 = styled.h5<IProps>`
  font-family: "Questrial", sans-serif;
  text-align: ${({ color }) => (color ? color : "#0000")};
  @media ${device.laptopL} {
    font-size: 25px;
  }
`;
export const H1 = styled.h5<IProps>`
  font-family:Montserrat;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: 50px;
  line-height: 100%;
  @media ${device.laptopL} {
    font-size:64px;
  }
  @media ${device.mobileL} {
    font-size:28px;
  }
`;
export const H6 = styled.h6<IProps>`
  font-family: "Questrial", sans-serif;
  @media ${device.laptopL} {
    font-size: 20px;
  }
`;

export const Subtitle = styled.h4<IProps>`
  font-style: normal;
  font-weight: bold;
   color: ${({ color }) => (color ? color : "#000")};
  font-family:Montserrat;
  line-height: 25px;
  margin-bottom: 10px;
  @media ${device.laptopL} {
    font-size: 24px;
  }
`;

export const Paragraph = styled.p<IProps>`
  font-style: normal;
  text-align: left;
  color: ${({ color }) => (color ? color : "#000")};
  font-size:  ${({ size }) => (size ? size : "12px")};
  font-family:Montserrat;
  @media ${device.laptopL} {
    font-size:17px;
  }
  @media ${device.mobileL} {
    font-size:12px;
  }
`;
export const ParagraphBold = styled.p<IProps>`
  font-style: normal;
  text-align: left;
  font-weight:bold;
  color: ${({ color }) => (color ? color : "#000")};
  font-size:  ${({ size }) => (size ? size : "12px")};
  font-family:Montserrat;
  line-height: 25px;
  font-size:  ${({ size }) => (size ? size : "12px")};
  @media ${device.laptopL} {
    font-size:16px;
  }
  @media ${device.mobileL} {
    
  }
`;

export const Span = styled.span<IProps>`
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #949292;
  margin-top: -4px;
`;

export const CardHeader = styled.p<IProps>`
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: left;
  color: #686767;
  @media ${device.laptopL} {
    font-size: 16px;
  }
`;
