import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import { H1, Paragraph, ParagraphBold} from '../../../components/Typography'
import styled from 'styled-components'
import { bg, bg10, bg11, instrutor, main } from '../../../images'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Modals as Modal, useUiStore } from '../../../stores/uiStore'
import { FaBriefcase, FaChalkboardTeacher, FaChartPie, FaDesktop, FaDraftingCompass, FaMoneyBillAlt, FaRocket, FaServer, FaUserGraduate, FaUserTie } from 'react-icons/fa'
import { ButtonPayCourse, CardCourse, CardImage, ContentCourse, ImageCardCourse, InfoCardCourse, Section } from '../style'
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Col, Divider, Grid, Rate, Row } from 'rsuite'
import { FaCartShopping, FaCheck, FaComputer } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/Buttons/Button'
import { Image, ImageMain, TextMargin, Title } from '../training-center'

const Universidade: React.FC = observer(() => {
    const { courseList } = useCoursePresenter();
    const navigate = useNavigate()
    return (
        <>
            <FrontLayout>
                <Section style={{margin:0, padding:0}}>
                    <Row>
                        <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{padding:10}}>
                            <TextMargin>
                                <Title  color='#333'>
                                Ofereça um ambiente de ensino e aprendizado flexivel,
                                para seus alunos, docentes e sua instituição .
                                </Title>
                                <Paragraph style={{marginTop:10}} size="18px">
                                    <FaCheck color={theme.BACKGROUND.BLUE}/> Equipe os estudantes com as competências mais requisitadas e prepare-os para o sucesso no trabalho;
                                </Paragraph>
                                
                                <Paragraph style={{marginTop:10}} size="18px">
                                    <FaCheck color={theme.BACKGROUND.BLUE}/> Ofereça experiências de aprendizagem práticas e relevantes para o trabalho com conteúdo profissional e cursos de graduação ministrados pela tua Universidade;
                                </Paragraph>
                                <Button label='FALE CONOSCO' width='100' color={theme.BACKGROUND.BLACK}/>
                                <Paragraph size="18px" style={{marginTop:15}}>
                                Veja suas opções, <Link to={""}> consultar planos </Link> .
                                </Paragraph>
                             </TextMargin>
                        </Col>
                        <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} xsHidden>
                        <ImageMain backgroundImage={bg11}/>
                        </Col>
                    </Row>
                </Section>
                <Section style={{backgroundColor:"#fff"}}>
                 <Grid fluid style={{padding:0}}>
                    <TextMargin>
                        <Row className="show-grid">
                            <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} >
                            <FaDesktop size={30} color={theme.BACKGROUND.BLUE}/> 
                            <Paragraph size="19px">
                            Ofereça aos teus estudantes o poder de estudar quando  quiser e onde estiver .
                            </Paragraph>
                            </Col>
                            <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} >
                            <FaChalkboardTeacher size={30} color={theme.BACKGROUND.BLUE}/> 
                            <Paragraph size="19px">
                                Aumente a democratização de conhecimentos por parte dos docentes . 
                            </Paragraph>
                            </Col>
                            <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24}>
                            <FaComputer size={30} color={theme.BACKGROUND.BLUE}/> 
                            <Paragraph size="19px">
                            Explore os recursos disponiveis  e aumente suas vendas .
                            </Paragraph>
                            </Col>
                        </Row>
                    </TextMargin>
                </Grid>
                </Section>
                <Section >
                <Grid fluid style={{padding:10}}>
                <Row className="show-grid">
                    <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                        <Image src={bg10}/>
                    </Col>
                    <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{textAlign:"left"}}>
                        <ParagraphBold color='#000' size="17px" style={{marginTop:10}}>
                            O que oferecemos ?
                        </ParagraphBold>
                        <Title color='#000' style={{lineHeight:1, marginBottom:10}}>
                            Oferecemos todos os recursos  para implementação  do elearning na sua instituição.
                        </Title>
                        <Paragraph size="18px">
                            Dos recursos tecnológicos, passando pelos humanos até aos pedagógicos .
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Fortaleça a empregabilidade na Rosa Academy ;
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Conecte-se com uma comunidade  global;
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Explore uma variedade de recursos disponível para  impulsionar sua instituição;
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ajude seus estudantes a aumentar a confiança no trabalho, aplicar o aprendido e aprimorar competências;
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ofereça aos estudantes projetos práticos para treinar competências e se destacar diante dos empregadores;
                        </Paragraph>
                        <Paragraph size="18px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Melhore as experiências de aprendizagem;
                        </Paragraph>
                    </Col>
                </Row>
                </Grid>
                </Section>
            </FrontLayout>
        </>
    )
})
export default Universidade;
interface IProps {
    backgroundImage?: string
}
