import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useUiStore, Modals, Drawers } from '../../stores/uiStore'
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { styled } from 'styled-components'
import Drawer from './DrawerWrapper'
import { Avatar, FlexboxGrid, List, RadioTile, RadioTileGroup } from 'rsuite'
import { COLORS } from '../../config/colors'
import { Paragraph, ParagraphBold, Title } from '../Typography'
import TextInput from '../Inputs/TextInput'
import { device } from '../../config/devices'
import Button from '../Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { useAuthPresenter } from '../../pages/front/presenter';
import { useUserStore } from '../../stores/userStore';
import { logo } from '../../images';
import { FaSafari, FaUserCog, FaUserEdit } from 'react-icons/fa';
import { MdOutlineDashboard } from 'react-icons/md';
import { Alert } from 'antd';
const Payment: React.FC = observer(() => {
    const ui = useUiStore()
    const { setDrawer } = ui
    const { loading, uploadComprovativoPayment} = useAuthPresenter()
    const { user } = useUserStore()
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            uploadComprovativoPayment(e.target.files[0])
        }
    }
    return (
        <>
            <Drawer
                isOpened={ui[Drawers.PAYMENT_COURSE_DRAWER]}
                onClose={() => {
                    setDrawer(Drawers.PAYMENT_COURSE_DRAWER, false)
                }}
                loadding={false}
                onSubmit={() => { }}>
                <Container>
                    {
                       user ?
                        <> 
                            <Title style={{marginBottom:10}}>
                            Finalizar pagamento
                            </Title>
                            <Alert message="Por favor envie-nos os comprovativos  de pagamento do curso, e aguarde até 48 horas  para aprovação do acesso ao seu curso .  " type="warning" showIcon  />
                            <ButtonUpload accept="image/*" onChange={onSelectFile} />
                            <Button label='Selecione  comprovativo ' loading={loading} color='#000'/>            
                        </>
                        :
                        <>
                            <Logo src={logo} /> 
                            <Paragraph style={{marginTop:10}}>
                                Caso tenha uma conta faça login, o contrario inscreva-se .
                            </Paragraph>
                            <Button 
                            label='Inscrever-se'
                            onClick={() => {
                                setDrawer(Drawers.SIGNUP_DRAWER, true)
                            }}   
                            width='100' 
                            color='#000'
                            />
                            <Button 
                            label='Entrar agora' 
                            onClick={() => {
                                setDrawer(Drawers.LOGIN_DRAWER, true)
                            }}   
                            width='100' 
                            color='#000'
                            />
                        </>
                    }
                  </Container>
            </Drawer>
        </>)

});
export default Payment;
const Container = styled.div`
  width: 100%;
   @media ${device.mobileL} {
    position:absolute;
    width:58%;margin-left:3%;
    left:0;  top:60px;
  }
`
const ButtonUpload = styled.input.attrs({ type: 'file' })`
appearance: none;
opacity: 0;width:80%;height:50px;
cursor: pointer;position:absolute;margin-top:10px;
    `
const Logo = styled.img`
height:30px;margin 0 auto;
`