import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useUiStore, Modals, Drawers } from '../../stores/uiStore'
import { FaUserLarge, FaLock } from "react-icons/fa6";
import { styled } from 'styled-components'
import Drawer from './DrawerWrapper'
import { RadioTile, RadioTileGroup } from 'rsuite'
import { COLORS } from '../../config/colors'
import { Paragraph, ParagraphBold, Title } from '../Typography'
import TextInput from '../Inputs/TextInput'
import { device } from '../../config/devices'
import Button from '../Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { useAuthPresenter } from '../../pages/front/presenter';
import { useUserStore } from '../../stores/userStore';
import { logo } from '../../images';
const Login: React.FC = observer(() => {
    const ui = useUiStore()
    const { setDrawer } = ui
    const { setFormValue, login, loading } = useAuthPresenter()
    return (
        <>
            <Drawer
                isOpened={ui[Drawers.LOGIN_DRAWER]}
                onClose={() => {
                    setDrawer(Drawers.LOGIN_DRAWER, false)
                }}
                loadding={false}
                onSubmit={() => { }}>
                <Container>
                    <Logo src={logo} /> 
                    <Title style={{marginTop:10}}>Entre na sua conta agora .</Title>
                    <Paragraph color='#000' style={{ marginTop: 15 }}>Faça login em sua conta para inscrever-se em qualquer curso e fazer parte de nossa comunidade de forma profundada.</Paragraph>
                    <TextInput
                        placeholder='Email :'
                        name="email"
                        icon={<FaUserLarge style={{ padding: 7 }} />}
                        onChange={setFormValue}
                    />
                    <TextInput
                        type="password"
                        name="password"
                        placeholder='Palavra Passe :'
                        icon={<FaLock style={{ padding: 7 }} />}
                        onChange={setFormValue}
                    />
                    <Link to={``} style={{ textDecoration: "none" }}>
                        <ParagraphBold style={{ marginTop: 10 }} color="#3a53b9">
                            Lembrar minha palavra passe
                        </ParagraphBold>
                    </Link>
                    <Button
                        loading={loading}
                        label='ENTRAR AGORA MESMO'
                        color='#3a53b9'
                        onClick={login}
                    />
                    <Paragraph style={{ marginTop: 10, textAlign: 'center' }}>
                        És novo na <strong><Link to={``}>Rosa Academy</Link></strong> ?
                        <strong>
                            <Link to={``}>{"  "} Criar uma nova conta </Link>
                        </strong> .
                    </Paragraph>
                </Container>

            </Drawer>
        </>)

});
export default Login;
const Container = styled.div`
  width: 100%;
  margin-top:90px;
  @media ${device.mobileL} {
    position:absolute;
    width:58%;margin-left:3%;
    left:0;  top:60px;
    margin-top:40px;
  }
`
const Logo = styled.img`
height:50px;margin 0 auto;
@media ${device.mobileL} {
 height:30px;
}
`