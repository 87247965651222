import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import styled from 'styled-components'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Section } from '../style'
import { Accordion, Breadcrumb, Col, FlexboxGrid, Rate, Row, Tabs } from 'rsuite'
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { Paragraph, ParagraphBold } from '../../../components/Typography'
import { FaCartShopping, FaChartSimple, FaFilm, FaHeart, FaInfinity, FaPlay, FaRegClock, FaRegUser, FaVolumeLow } from 'react-icons/fa6'
import { MdAccessTime, MdOutlineOndemandVideo } from 'react-icons/md'
import Button from '../../../components/Buttons/Button'
import { FaMobileAlt } from 'react-icons/fa'
import { useLayoutEffect, useRef } from "react";
import { useAuthPresenter } from '../presenter'
const CourseDetail: React.FC = observer(() => {
    const { getFormatCurrent, getCourseId, course, getModulesByCourseId, moduleList, getQtdAula } = useCoursePresenter()
    
    const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: 'panda-f14c442064bd80437574f7830f96562766e91e37b81e798ad8df64974db9e767'
        }
      };
      
      fetch('https://api-v2.pandavideo.com.br/videos//', options)
        .then(res => res.json())
        .then(res => console.log(res))
        .catch(err => console.error(err));

    const { id_course } = useParams();
    const navigate = useNavigate()
    const div = document.getElementById("infocourse");
  
    const onScroll = () => {
        if (div) {
            const scrolledTo = window.scrollY + window.innerHeight;
            if(window.innerWidth >1080){
                if (window.scrollY >= 90 ) {
                    div.style.position = "absolute";
                    div.style.top = `${(window.scrollY * 0.55) - 200}px`
                } else {
                    div.style.position = "absolute";
                    div.style.top = `${window.scrollY}px`
                }
            }else{
                div.style.position = "relative";
                div.style.marginTop="0";
            }
            
        }
    }
    window.addEventListener("scroll", onScroll);
    
    React.useEffect(() => {
        getCourseId(id_course as string);
        getModulesByCourseId(id_course as string)
    }, [])
    return (
        <>
            <Section>

                {course &&
                    <Content>
                        <Row className="show-grid">
                            <Col lg={14} xl={16} xxl={16} md={24} sm={24} xs={23}>
                                <GridAboutCourse>
                                    <h1 color='#000' >
                                        {course?.titulo}
                                    </h1>
                                    <Paragraph size={18}>
                                        Um curso de <strong>{course.users?.name}</strong>, {course.users?.profissao} .
                                    </Paragraph>
                                    <GridDetailCourse>
                                        <FlexboxGrid justify="space-between" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}>
                                            <FlexboxGrid.Item colspan={12} xl={14} as={Col}  xs={24} >
                                                <Paragraph>
                                                    <Rate defaultValue={4} size="xs" color="yellow" /> 95% de avaliações positivas
                                                </Paragraph>

                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item as={Col} xl={4} colspan={6} xs={24}>
                                                <Paragraph>
                                                    <FaRegUser color='#999' style={{ marginLeft: 0 }} /> 6 estudantes
                                                </Paragraph>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item as={Col} xl={5} colspan={6} xs={24}>
                                                <Paragraph>
                                                    <FaVolumeLow color='#999' style={{ marginLeft: 0 }} /> Áudio: Português
                                                </Paragraph>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </GridDetailCourse>
                                    <Row>
                                        <Col lgHidden xlHidden xxlHidden mdHidden xs={24}>
                                        <Button
                                            label='Adicionar ao carrinho'
                                            icon={<FaHeart />}
                                            color={theme.BACKGROUND.BLUE_OCEAN}
                                        />
                                        <Button
                                            label='Adquirir curso agora'
                                            onClick={() => {
                                                navigate('/cursos/' + id_course + '/checkout')
                                            }}
                                            icon={<FaCartShopping />}
                                            color={theme.BACKGROUND.GREEN}
                                        />
                                        </Col>
                                    </Row>
                                    {course.link_promocional &&<div style={{position:"relative", paddingTop:"56.25%", marginBottom:10}}>
                                        <iframe 
                                        id={`panda-${course.link_promocional}`} 
                                        src={`https://player-vz-d8039a58-ccd.tv.pandavideo.com.br/embed/?v=${course.link_promocional}`}
                                        style={{border:"none", position:"absolute", top:0, left:0}} 
                                        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" 
                                        allowFullScreen={true} 
                                        width="100%" 
                                        height="100%" />
                                    </div>
                                    }
                                    
                                    <Tabs defaultActiveKey="1" appearance="subtle">
                                        <Tabs.Tab eventKey="1" title="Grade curricular do curso ">
                                            <GridAboutCourse>
                                                <ParagraphBold size="16px" >
                                                    Resumo do curso
                                                </ParagraphBold>
                                                <GridDetailCourse>
                                                    <FlexboxGrid justify="space-between" style={{ marginTop: 10, marginBottom: 10 }}>
                                                        <FlexboxGrid.Item colspan={8}>
                                                            {moduleList &&
                                                                <Paragraph size="16px" style={{ textAlign: "center" }}>
                                                                    <MdOutlineOndemandVideo size={32} color='#999' /> <br /><strong>{moduleList.length} </strong> Módulos
                                                                </Paragraph>}
                                                        </FlexboxGrid.Item>
                                                        <FlexboxGrid.Item colspan={8}>
                                                            {moduleList &&
                                                                <Paragraph size="16px" style={{ textAlign: "center" }}>
                                                                    <MdAccessTime size={32} color='#999' /><br />
                                                                    09h:50min
                                                                </Paragraph>}
                                                        </FlexboxGrid.Item>
                                                        <FlexboxGrid.Item colspan={8}>
                                                            {moduleList &&
                                                                <Paragraph size="14px" style={{ textAlign: "center" }}>
                                                                    <FaFilm size={32} color='#999' /> <br />
                                                                    <strong>{getQtdAula(moduleList)} </strong> Aulas
                                                                </Paragraph>}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid>
                                                </GridDetailCourse>


                                                {moduleList && <Accordion defaultActiveKey={1} >
                                                    {moduleList.map((item, i) => (
                                                        <Accordion.Panel header={`Módulo ${i + 1}- ${item?.title}`} eventKey={i + 1}>
                                                            {item?.items_module && <>
                                                                {item?.items_module.map((item1, index) => (
                                                                    <GridAula>
                                                                        <FlexboxGrid justify="space-between" style={{ marginRight: 10 }}>
                                                                            <FlexboxGrid.Item colspan={22}>
                                                                                <Paragraph size="14px" style={{ marginLeft: 20 }}><strong>Aula {index + 1} - </strong>{item1.titulo}</Paragraph>
                                                                            </FlexboxGrid.Item>
                                                                            <FlexboxGrid.Item colspan={2} >
                                                                                {i == 0 && index == 0 ?
                                                                                    <Paragraph style={{ textAlign: "right", }}>
                                                                                        <FaPlay size={16} color={theme.BACKGROUND.GREEN} />
                                                                                    </Paragraph>
                                                                                    :
                                                                                    <Paragraph color='#999' style={{ textAlign: "right", }}>
                                                                                        00:00:00
                                                                                    </Paragraph>}
                                                                            </FlexboxGrid.Item>
                                                                        </FlexboxGrid>
                                                                    </GridAula>
                                                                ))}
                                                            </>}
                                                        </Accordion.Panel>
                                                    ))}
                                                </Accordion>}
                                            </GridAboutCourse>
                                        </Tabs.Tab>
                                        <Tabs.Tab eventKey="2" title="Mais Informações">
                                            <GridDetailCourse>
                                                <ParagraphBold size="16px" style={{ marginLeft: 20 }}>
                                                    Para quem é este curso on-line?
                                                </ParagraphBold>
                                                <Paragraph size="16px" style={{ marginLeft: 20, marginRight: 20 }}>
                                                    {course?.para_quem}
                                                </Paragraph>
                                            </GridDetailCourse>
                                            <GridDetailCourse>
                                                <ParagraphBold size="16px" style={{ marginLeft: 20 }}>
                                                    Descrição
                                                </ParagraphBold>
                                                <Paragraph size="16px" style={{ marginLeft: 20, marginRight: 20 }}>
                                                    {course?.resume}
                                                </Paragraph>
                                            </GridDetailCourse>
                                        </Tabs.Tab>
                                    </Tabs>
                                </GridAboutCourse>
                            </Col>
                            <Col  lg={10} xl={8} xxl={8} md={24} sm={24} xsHidden mdHidden >
                                <GridDetailCourse id="infocourse">
                                    <DetailWrapper>
                                        <Paragraph>
                                            Preço total
                                        </Paragraph>
                                        <ParagraphBold color={theme.BACKGROUND.GREEN} style={{ textAlign: "left" }} size="27px" >
                                        {course?.price ? 
                                           `${getFormatCurrent(course?.price as number)} Kz`
                                           :
                                            "Grátis"
                                            }
                                        </ParagraphBold>
                                        <hr />
                                        <ParagraphBold size="15px" style={{ margin: 0 }}>
                                            <FaChartSimple /> Nível Iniciante
                                        </ParagraphBold>
                                        <Paragraph style={{ margin: 0 }}>Experiência recomendada</Paragraph>
                                        <hr />
                                        <ParagraphBold>
                                            Este curso inclui :
                                        </ParagraphBold>
                                        {moduleList && <Paragraph size="14px" >
                                            <MdOutlineOndemandVideo color='#999' style={{ marginRight: 10 }} /> {moduleList.length} Módulos
                                        </Paragraph>}
                                        {moduleList &&
                                            <Paragraph size="14px" >
                                                <FaFilm color='#999' style={{ marginRight: 10 }} /> {getQtdAula(moduleList)} Aulas
                                            </Paragraph>}
                                        <Paragraph size="14px">
                                            <FaRegClock color='#999' style={{ marginRight: 10 }} /> 09h:50min de vídeo sob demanda
                                        </Paragraph>
                                        <Paragraph size="14px">
                                            <FaMobileAlt color='#999' style={{ marginRight: 10 }} /> Acesso no dispositivo móvel e na TV
                                        </Paragraph>
                                        <Paragraph size="14px">
                                            <FaInfinity color='#999' style={{ marginRight: 10 }} /> Acesso total vitalício.
                                        </Paragraph>
                                        <Paragraph size="14px">
                                            <FaVolumeLow color='#999' style={{ marginRight: 10 }} /> Áudio: Português
                                        </Paragraph>
                                        <Paragraph size="14px">
                                            <FaRegUser color='#999' style={{ marginRight: 10 }} /> 6 estudantes
                                        </Paragraph>
                                        <hr />
                                        <ParagraphBold size="14px">
                                            Um curso de :
                                        </ParagraphBold>
                                        <FlexboxGrid justify="space-between" style={{ marginTop: 10, marginBottom: 10, marginLeft: 20 }}>
                                            <FlexboxGrid.Item colspan={5}>
                                                <CardImgProfile>
                                                    <img src={course.users?.img_url} />
                                                </CardImgProfile>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={19}>
                                                <ParagraphBold size="14px" style={{ textAlign: "left", marginTop: 10, marginBottom: 0 }}>
                                                    {course.users?.name}
                                                </ParagraphBold>
                                                <Paragraph color='#999' style={{ textAlign: "left", padding: 0, margin: 0 }}>
                                                    Desenvolvedor de software
                                                </Paragraph>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        <hr />
                                        <Button
                                            label='Adicionar ao carrinho'
                                            icon={<FaHeart />}
                                            color={theme.BACKGROUND.BLUE_OCEAN}
                                        />
                                        <Button
                                            label='Adquirir curso agora'
                                            onClick={() => {
                                                navigate('/cursos/' + id_course + '/checkout')
                                            }}
                                            icon={<FaCartShopping />}
                                            color={theme.BACKGROUND.GREEN}
                                        />
                                    </DetailWrapper>

                                </GridDetailCourse>
                            </Col>
                        </Row>
                    </Content>
                }
            </Section >

        </>
    )
})
export default CourseDetail
interface IProps {
    backgroundImage?: string
}
const CardBreadcrumb = styled.div`
  width: 100%;height:50px;
  border-bottom:1px solid #e0e0e0;
  
`

const DetailWrapper = styled.div`
  padding:20px 20px 
  
`
const Content = styled.div`
  width: 90%;margin:auto;
  @media ${device.laptopL} {
    width:80%;
  }
  @media ${device.mobileL} {
    width:96%;
  }
`
const GridAboutCourse = styled.div`
  width: 100%;
  margin-top:0px;
  margin-bottom:20px;
  h1{
  font-family:Montserrat;
  @media ${device.laptopL} {
    font-size:60px;margin-bottom:18px;
  }
  @media ${device.mobileL} {
    font-size:25px;
    line-height: 19px;
    margin-bottom:10px;
  }
  }
`
const GridDetailCourse = styled.div`
  width: 100%;
  margin-bottom:10px;
  margin-top:10px;
 background-color:#fff;
 padding:10px 0;
 border:1px solid #e0e0e0;
 border-radius:6px;
 @media ${device.mobileL} {
    
  }
`
const GridAula = styled.div`
  width: 100%;margin-top:10px;
 background-color:#fff;padding:15px 0;
 border:1px solid #e0e0e0;
 border-radius:5px;
`
const ButtonPromocional = styled.button`
width:80px;height:80px;
background-color:${theme.BACKGROUND.BLUE};
margin:auto;
border-radius:50%;
`
const CardImgProfile = styled.div`
width:70px;
height:70px;
border:1px solid #e9e9e9;
border-radius:50%;
img{
width:60px;margin:5px;
border-radius:50%;
}
  
`