import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import SupabaseGateway from '../gateways/SupabaseGateway'
import { Tables } from '../types/enums'
import { useClassStore } from '../util/useClassStore'
import container from './ioc'
import UIStore, { AlertType } from './uiStore'
@injectable()
class CourseStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway

  @inject(UIStore) ui!: UIStore
  courses!: ICourse[]
  aprendizados!: IAprendizados[]
  courselist!: ICourseList[]
  modulos!: IModule[]
  courseSaved = false;
  constructor() {
    makeAutoObservable(this)
  }
  resetCourseSaved = () => {

    this.courseSaved = false
  }
  getCourses = async () => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTable<ICourse>(Tables.cursos, `*,users(*)`)
      runInAction(() => {
        this.courses = data as ICourse[]
      })
      if (error) throw new Error(error.message)
      return data
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }

  uploadImage = async (image: File, course: ICourse) => {
    try {

      const { publicUrl } = await this.supabaseGateway.uploadFile<ICourse>(
        image,
        "ficheiros"
      )
      course.img_url = publicUrl;
      delete course.users;
      const { error } = await this.supabaseGateway.updateTable<ICourse>(
        Tables.cursos,
        course,
        { id: course.id }
      )
      console.log(error)
      await this.getCourseById(course.id as string)
      await this.getCourses()
      this.ui.showAlert('Imagem alterada com sucesso ...', AlertType.success)

    } catch (error) {

    }
  }

  getCourseById = async (id: string) => {
    try {
      const { data, error } = await this.supabaseGateway.getOneById(Tables.cursos,
        `*,users(*)`,
        id,
        "id");
      return data;
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  getCourseByCategoria = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.cursos,
          `*, modulos(*)`,
          { column: 'categoria', operator: 'eq', value: id }
        )
      runInAction(() => {
        this.courselist = data as ICourseList[]
      })
      if (error) throw new Error(error.message)
      return data
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  getModulesByCourseId = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.modulos,
          `*, items_module(*)`,
          {
            // @ts-ignore: object type
            column: 'course',
            operator: 'eq',
            value: id
          }
        )
      runInAction(() => {
        this.modulos = data as IModule[]
      })
      if (error) throw new Error(error.message)
      return data
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  getAprendizadosByCourseId = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.aprendizados,
          `*`,
          {
            // @ts-ignore: object type
            column: 'course',
            operator: 'eq',
            value: id
          }
        )
      runInAction(() => {
        this.aprendizados = data as IAprendizados[]
      })
      if (error) throw new Error(error.message)
      return data
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  update = async (data: ICourse) => {
    try {
      const { error } = await this.supabaseGateway.updateTable<ICourse>(
        Tables.cursos,
        data,
        { id: data.id }
      )
      await this.getCourses()
      this.ui.showAlert('Informações Guardadas', AlertType.success)
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  updateItemModule = async (item_module: IITEM_MODULE) => {
    try {
      const { error } = await this.supabaseGateway.updateTable<IITEM_MODULE>(
        Tables.items_module,
        item_module,
        { id: item_module.id }
      )

      await this.getModulesByCourseId(item_module?.course as string)
      this.ui.showAlert('Informações Guardadas', AlertType.success)
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  saveModules = async (modules: IModule[]) => {
    try {
      const { error, data } = await this.supabaseGateway.insertToTable<
        IModule[]
      >(Tables.modulos, modules)
      if (error) throw new Error(error.message)
      this.ui.showAlert('Informações Guardadas', AlertType.success)
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  saveAprendizado = async (aprendizados: IAprendizados[]) => {
    try {
      const { error, data } = await this.supabaseGateway.insertToTable<
        IAprendizados[]
      >(Tables.aprendizados, aprendizados)
      if (error) throw new Error(error.message)
      this.ui.showAlert('Informações Guardadas', AlertType.success)
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  AddCourse = async (course: ICourse) => {
    try {
      const { error, data } = await this.supabaseGateway.insertToTable<ICourse>(
        Tables.cursos,
        course
      )
      if (error) throw new Error(error.message)
      await this.getCourses()
      this.ui.showAlert('Curso Adicionado', AlertType.success)
      this.courseSaved = true
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
  AddItemModule = async (item_module: IITEM_MODULE) => {
    try {
      const { error, data } = await this.supabaseGateway.insertToTable<IITEM_MODULE>(
        Tables.items_module,
        item_module
      )
      if (error) throw new Error(error.message)
      await this.getCourses()
      this.ui.showAlert('Item Adicionado', AlertType.success)
      this.courseSaved = true
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error)
    }
  }
}

export const useCourseStore = () =>
  useClassStore<CourseStore>(container.get(CourseStore))

export default CourseStore
