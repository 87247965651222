import AppRoutes from './routes'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'rsuite/dist/rsuite.min.css'
import 'react-image-crop/dist/ReactCrop.css'
import "react-multi-carousel/lib/styles.css";
import { observer } from 'mobx-react-lite'

function App() {
  return (
    <div>
      <ToastContainer />
      <AppRoutes />
    </div>
  );
}

export default observer(App);
