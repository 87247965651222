import { observer } from 'mobx-react-lite'
import { FaImages } from "react-icons/fa6";
import { useParams } from 'react-router-dom'
import { Paragraph, ParagraphBold, Title } from '../../../../../components/Typography'
import TextInputLarge from '../../../../../components/Inputs/TextInputLarge'
import React, { useRef, useState } from 'react'
import { useCoursePresenter } from '../presenter'
import Button from '../../../../../components/Buttons/Button'
import theme from '../../../../../theme'
import styled from 'styled-components'
import { Col, Row } from 'rsuite'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { FaLink } from 'react-icons/fa';
import { useDebounceEffect } from '../../../../../components/canva/useDebounceEffect';
import { canvasPreview } from '../../../../../components/canva/previewCanva';
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
const TO_RADIANS = Math.PI / 180
const ManageImage: React.FC = observer(() => {

    const { getCourseId,update, course, imgSrc, setImgSrc, uploadImageCurso, setFormValue, loaddingUploadImage,loadding } = useCoursePresenter()
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [aspect] = useState<number | undefined>(16 / 12)
    const imgRef = useRef<HTMLImageElement>(null)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [crop, setCrop] = useState<Crop>()
    const [scale] = useState(1)
    const [rotate] = useState(0)
    let { id_course } = useParams();
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }
    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }
    async function uploadImage() {
        const ctx = previewCanvasRef.current ? previewCanvasRef.current.getContext('2d') : null;
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        if (!ctx) {
            throw new Error('No 2d context')
        }
        const scaleX = image ? image?.naturalWidth / image?.width : null;
        const scaleY = image ? image?.naturalHeight / image?.height : null;
        const pixelRatio = window.devicePixelRatio;
        if (canvas && completedCrop && scaleX && scaleY && image) {
            canvas.width = Math.floor(completedCrop?.width * scaleX * pixelRatio);
            canvas.height = Math.floor(completedCrop?.height * scaleY * pixelRatio);

            ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'

            const cropX = completedCrop.x * scaleX
            const cropY = completedCrop.y * scaleY

            const rotateRads = rotate * TO_RADIANS
            const centerX = image.naturalWidth / 2
            const centerY = image.naturalHeight / 2
            ctx.save()

            // 5) Move the crop origin to the canvas origin (0,0)
            ctx.translate(-cropX, -cropY)
            // 4) Move the origin to the center of the original position
            ctx.translate(centerX, centerY)
            // 3) Rotate around the origin
            ctx.rotate(rotateRads)
            // 2) Scale the image
            ctx.scale(scale, scale)
            // 1) Move the center of the image to the origin (0,0)
            ctx.translate(-centerX, -centerY)
            ctx.drawImage(
                image,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
                0,
                0,
                image.naturalWidth,
                image.naturalHeight,
            )
            if (previewCanvasRef.current) {
                previewCanvasRef.current.toBlob(
                    (blob: any) => {
                        const newImage = new File([blob], blob.name, { type: blob.type, });
                        uploadImageCurso(newImage);
                    },
                    'image/jpg',
                    1
                )
            }
        }
    }

    React.useEffect(() => {
        getCourseId(id_course as string);
    }, [])

    return (
        <>
            <Title color='#000' style={{ margin: 0, marginLeft: 0 }}>{course?.titulo}</Title>
            <Paragraph size="18px" color="#888" style={{  marginTop: 10 }}>Imagem e vídeo promocional do curso</Paragraph>
            <hr />
            <Row className="show-grid">
                <Col xxl={14} xl={14} xs={24}>
                    <Paragraph size="13">Faça o upload da imagem do seu curso aqui. Ela deve atender aos nossos padrões de qualidade da imagem do curso para ser aceita. Diretrizes importantes: ter 750 x 422 pixels, estar no formato .jpg, .jpeg,. gif ou .png. e não ter nenhum texto na imagem.</Paragraph>
                    <ParagraphBold>Carregar a imagem de capa do curso</ParagraphBold>
                    <ButtonUpload accept="image/*" onChange={onSelectFile} />
                    <Button label='Selecionar imagem do curso ' />
                </Col>
                <Col  xxl={10} xl={10} xs={24}>
                    <ImagemCourse>
                        {imgSrc ?
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => {
                                    setCompletedCrop(c)
                                }}
                                aspect={aspect}
                                minWidth={470}
                                minHeight={300}
                            // circularCrop
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            : course?.img_url ?
                                <ImageCourse src={course?.img_url} />
                                :
                                <ImageBackground>
                                    <FaImages size={150} color='#d0d0d0' />
                                </ImageBackground>}


                    </ImagemCourse>
                    {imgSrc && <Button label='Salvar imagem' onClick={uploadImage} loading={loaddingUploadImage} color={theme.BACKGROUND.GREEN} />}
                    {!!completedCrop && (
                        <>
                            <div>
                                <canvas
                                    ref={previewCanvasRef}
                                    id="canva"
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                        display: "none"
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Col>
            </Row>
            <hr />
            <ParagraphBold>Id do vídeo promocional</ParagraphBold>
            <TextInputLarge
                name='link_promocional'
                onChange={setFormValue}
                defaultValue={course?.link_promocional}
                placeholder='Id do vídeo promocional do curso  :'
                icon={<FaLink color='#999' size={18} style={{ margin: 13 }} />}
            />
            <Button 
            label='Salvar link do curso'
            width='50' 
            loading={loadding}
            color={theme.BACKGROUND.BLUE} 
            onClick={update}
            />
        </>
    )
})

export default ManageImage
const ButtonUpload = styled.input.attrs({ type: 'file' })`
appearance: none;
opacity: 0;width:100%;height:50px;
cursor: pointer;position:absolute;margin-top:10px;
    `
const ImagemCourse = styled.div`
width:95%;min-height:300px; 
border:1px solid #e9e9e9;
padding:10px;       
`
const ImageBackground = styled.div`
width:100%;min-height:300px; 
background-color:#f0f0f0;     
display: flex;
align-items: center;
justify-content: center;
`
const ImageCourse = styled.img`
width:100%;min-height:300px; 
`