import { Col, Grid, Rate, Row } from "rsuite"
import styled from "styled-components";
import { Paragraph, ParagraphBold } from "../Typography";
import theme from "../../theme";
import { MdEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";

interface IProps {
    data: Array<ICourse>
}
const GridCourse = ({ data }: IProps) => {
    const navigate = useNavigate()
    const{sliceString} = useCoursePresenter()
    return (
        <Grid fluid>
            <Row gutter={16}>
                {data.map((item, i) => (
                    <Col xs={12} lg={4} xl={4} xxl={4} style={{ marginBottom:10 }}>
                         <Link to={`${item?.id}/manage/basic`} style={{textDecoration:"none"}}>
                         <div className="show-col">
                           <ImageCourse src={item?.img_url} />
                            <InfoCourse>
                                <ParagraphBold style={{ marginLeft: 10, marginRight: 10 }}>
                                    {sliceString(item?.titulo as string)}
                                </ParagraphBold>
                                <div>
                                    <Paragraph size="11px" style={{ marginLeft: 10, marginRight: 10 }}>
                                        <strong>2.7</strong> <Rate defaultValue={2} color="yellow" size="xs" />(43)
                                    </Paragraph>
                                    <ParagraphBold color={theme.BACKGROUND.GREEN} style={{ marginLeft: 10, marginRight: 10 }}>{item?.price ? "" : "Grátis"}</ParagraphBold>
                                </div>
                            </InfoCourse>
                        </div></Link>
                    </Col>  
                ))}

            </Row>
        </Grid>
    )
}
export default GridCourse;
const ImageCourse = styled.img`
  width:100%;
`
const InfoCourse = styled.div`
  width:100%;padding:10px 0;
  background-color:#fff;
`
const MyButton = styled.button`
margin-right:5px;padding:6px;
margin-top:10px;font-family:Montserrat;
font-size:11px;
`