import { observer } from 'mobx-react-lite'
import { Route, Routes, useParams } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react';
import { useNavigate } from "react-router-dom";
import { FaCircleInfo, FaListOl } from 'react-icons/fa6';
import type { MenuProps } from 'antd';
import { Layout, Menu, } from 'antd';
import EditarProfile from './config/EditarProfile';
import ConfigCount from './config/Config';
import Percurso from './percurso';
const { Sider } = Layout;
const User: React.FC = observer(() => {

    return (
        <>
            <Routes>
                <Route path="editar-profile" element={<EditarProfile />} />
                <Route path="config" element={<ConfigCount />} />
                <Route path="meu-percurso" element={<Percurso />} />
                <Route path="meu-percurso" element={<Percurso />} />
            </Routes>
        </>
    )
});
export default User;