import { observer } from 'mobx-react-lite'
import { FaHouseUser, FaReact, FaRocket, FaUserGraduate, FaUsers } from 'react-icons/fa'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Paragraph, ParagraphBold, Title } from '../../../../../components/Typography'
import TextInputLarge from '../../../../../components/Inputs/TextInputLarge'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6'
import Select from "react-select";
import React from 'react'
import { useCoursePresenter } from '../presenter'
import { categoriasCurso, levelCurso } from '../../../../../config/static'
import Button from '../../../../../components/Buttons/Button'
import theme from '../../../../../theme'
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
const ManageBasic: React.FC = observer(() => {
    const placeholder = 'Escreve a descrição do curso ...';
    const theme1 = 'snow';
    const modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        
        ],
      };
    const formats = ['bold', 'italic', 'underline', 'strike'];
    const { quill, quillRef} = useQuill({modules,theme:theme1,placeholder});
    const { getCourseId, course, update,loadding, setFormValue, setCategoria, setLevel} = useCoursePresenter()
    let { id_course } = useParams(); 
    React.useEffect(() => {
        getCourseId(id_course as string);
    }, []);
    return (
        <>
            <Title color='#000' style={{ margin: 0, marginLeft: 0 }}>{course?.titulo}</Title>
            <Paragraph color="#888" size="18px" style={{  marginTop: 10 }}>Página inicial do curso</Paragraph>
            <hr />
            <Paragraph>
                A página inicial do curso é essencial para o seu sucesso na <Link to={""}><strong>Rosa Academy</strong></Link>. Se feita corretamente, ela também pode ajudar você a ter mais visibilidade em mecanismos de pesquisa como o Google. Ao preencher esta seção, pense na criação de uma página inicial do curso atraente que demonstre porque alguém iria querer se inscrever no seu curso.
            </Paragraph>
            <ParagraphBold>Título do curso</ParagraphBold>
            <TextInputLarge
                name='titulo'
                onChange={setFormValue}
                defaultValue={course?.titulo ? course.titulo : ""}
                icon={<FaBookmark size={20} style={{ margin: 12 }} />}
            />
            <Paragraph size="11px">O título deve ser chamativo, informativo e otimizado para pesquisa</Paragraph>
            <ParagraphBold>Subtítulo do curso</ParagraphBold>
            <TextInputLarge
                name='subtitulo'
                onChange={setFormValue}
                placeholder='Escrever Subtítulo :'
                defaultValue={course?.subtitulo ? course.subtitulo : ""}
                icon={<FaRegBookmark size={20} style={{ margin: 12 }} />}
            />
            <Paragraph size="11px">Use uma ou duas palavras-chave relacionadas e mencione três a quatro áreas importantes que serão abordadas durante o curso.</Paragraph>
            <ParagraphBold>Para quem desejas compartilhar o conhecimento ?</ParagraphBold>
            <TextInputLarge
                name='para_quem'
                placeholder='Para quem  foi criado o curso :'
                onChange={setFormValue}
                defaultValue={course?.para_quem ? course.para_quem : ""}
                icon={<FaUsers size={20} style={{ margin: 12 }} />}
            />
            <ParagraphBold style={{ marginTop: 18 }}>O que é essencialmente ensinado em seu curso?</ParagraphBold>
            <TextInputLarge
                name='ensinado'
                placeholder='O que será ensinado  :'
                onChange={setFormValue}
                defaultValue={course?.ensinado ? course.ensinado : ""}
                icon={<FaRegBookmark size={20} style={{ margin: 12 }} />}
            />
            {course?.categoria &&
                <>
                    <ParagraphBold style={{ marginTop: 18 }}>Categoria do curso?</ParagraphBold>
                    <Select options={categoriasCurso}
                        placeholder="------------Selecionar uma categoria-------------"
                        onChange={(data) => {
                            const { label, value } = data as { label: string, value: string };
                            setCategoria(value)
                        }}
                        defaultValue={
                            categoriasCurso.find(option => option.value == course?.categoria)
                        }
                        styles={{
                            control: (provided: any) => ({
                                ...provided, height: 50, marginTop: 0,
                                marginRight: 10, borderRadius: 0,
                                textAlign: "left",
                                fontFamily: "Montserrat", width: "100%"
                            }),
                            menu: (provided: any) => ({
                                ...provided,
                                zIndex: 3,
                                textAlign: "left",
                            }),
                        }}
                    />
                </>
            }
            <ParagraphBold style={{ marginTop: 18 }}>Nível do curso?</ParagraphBold>
            <Select options={levelCurso}
                placeholder="---------Selecionar um nível---------"
                onChange={(data) => {
                    const { label, value } = data as { label: string, value: string };
                    setLevel(value)
                }}
                defaultValue={
                    levelCurso.find(option => option.value == course?.level)
                }
                styles={{
                    control: (provided: any) => ({
                        ...provided, height: 50, marginTop: 0,
                        marginRight: 10, borderRadius: 0,
                        textAlign: "left",
                        fontFamily: "Montserrat", width: "100%"
                    }),
                    menu: (provided: any) => ({
                        ...provided,
                        zIndex: 3,
                        textAlign: "left",
                    }),
                }}
            />
            <ParagraphBold style={{ marginTop: 18 }}>Descrição do curso?</ParagraphBold>
            <div style={{ width: "100%", height: 100}}>
                <div ref={quillRef} />
            </div>
            <br/><br/>
            <Button 
                label='Salvar informações do curso' 
                width='50' 
                loading={loadding}
                onClick={update}
                color={theme.BACKGROUND.BLUE} 
            />
        </>
    )
})

export default ManageBasic
