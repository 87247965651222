import { observer } from 'mobx-react-lite'
import { Route, Routes, useParams } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react';
import { useNavigate } from "react-router-dom";

import { FaCircleInfo, FaListOl } from 'react-icons/fa6';
import type { MenuProps } from 'antd';
import { Layout, Menu, } from 'antd';
import CourseDetail from './CourseDetail';
import FrontLayout from '../../../layouts/FrontLayout';
import Checkout from './checkout';
const { Sider } = Layout;

const CourseFront: React.FC = observer(() => {
    const navigate = useNavigate()
    return (
        <>
            <FrontLayout>
                <CourseBody>
                    <Routes>
                        <Route path="detail" element={<CourseDetail />} />
                        <Route path="checkout" element={<Checkout />} />
                    </Routes>
                </CourseBody>
            </FrontLayout>
        </>

    )
})
const CourseBody = styled.div`
 
`;
export default CourseFront
