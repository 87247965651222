import { inject, injectable } from 'inversify'
import { makeAutoObservable, autorun, runInAction } from 'mobx'
import { ChangeEvent } from 'react'
import container from '../../stores/ioc'
import { useClassStore } from '../../util/useClassStore'
import NavigationStore from '../../stores/navigationStore'
import SupabaseGateway from '../../gateways/SupabaseGateway'
import UIStore, { AlertType } from '../../stores/uiStore'
import UserStore from '../../stores/userStore'
import PurchasedCourseStore from '../../stores/purchasedCourseStore'
import { getValidationErrorMessage, SIGNUP_FORM_VALIDATOR } from '../../util/validator'
import { useNavigate } from 'react-router-dom'
@injectable()
class AuthPresenter {
    [key: string]: string | undefined | any
    @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway
    @inject(UIStore) ui!: UIStore
    @inject(UserStore) private userStore!: UserStore
    @inject(PurchasedCourseStore) private purchasedStore!: PurchasedCourseStore
    @inject(NavigationStore) private navigation!: NavigationStore;
    user!: IUser
    loading = false
    loaddingUploadImage = false
    email: string = '';
    password: string = '';
    name:string = '';
    confirm_password :string = '';
    purchasedSaved = false;
    purchased!:IPurchasedCourse;
    constructor() {
        makeAutoObservable(this)
        autorun(async () => {
            await this.getPurchasedByUserId()
        })
    }

    setFormValue = (e: ChangeEvent<HTMLInputElement>) => {
        this[e.target.name] = e.target.value
    }
    setCourseId = (id: string) => {
        this.purchased = {
            ...this.purchased,
            id_course: id
        }
    }
    resetStoreValuePurchased = () => {
        this.purchasedSaved = false
    }
    signUp = async () => {
        try {
          this.loading = true
          const user = {
            name:this.name, email:this.email,
            password:this.password, 
            confirm_password:this.confirm_password
          }
          await SIGNUP_FORM_VALIDATOR.validate(user, { abortEarly: false })
          await this.userStore.signUp(this.name, this.email, this.password)
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                this.ui.showAlert(
                    getValidationErrorMessage(err.path) + err.message,
                    AlertType.error
                )
            })
        } finally {
          this.loading = false
        }
      }
    login = async () => {
        try {
            this.loading = true
            await this.userStore.login(this.email, this.password)
        } catch (error) {
        } finally {
            this.loading = false
            if (!this.userStore.user) return
            this.navigation.push('/')
        }
    }
    uploadComprovativoPayment = async (img: File) => {
        try {
            this.loading = true;
            this.purchased = {
                ...this.purchased,
                id_user: this.userStore.user.id,
                categoria: "1"
            }
            await this.purchasedStore.uploadComprovativoPayment(img, this.purchased);
            this.loadding = false
        } catch (error) {
            this.loadding = false
        } finally {
            runInAction(() => {
                this.loadding = false
            })
        }
    }
    addPurchase = async () => {
        try {
            this.loading = true;
            this.purchased = {
                ...this.purchased,
                id_user: this.userStore.user.id,
                categoria: "1"
            }
            await this.purchasedStore.addPurchased(this.purchased)
            this.purchasedSaved = true
        } catch (error: any) {
        } finally {
            this.loading = false
        }
    }
    get purchasedList(): IPurchasedCourse[] {
        return this.purchasedStore.purchasedList
    }
    getPurchasedByUserId = async () => {
        try {
            await this.purchasedStore.getPurchasedByUser(this.userStore.user.id as string)
        } catch (error: any) {
        }
    }
}
export const useAuthPresenter = () =>
    useClassStore<AuthPresenter>(container.get(AuthPresenter))

export default AuthPresenter