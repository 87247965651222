export default {
    COLOR: {
        THEME: "#000",
        WHITE: "#FFF"
    },
    BACKGROUND: {
        THEME: "#131b3c",
        BLUE: "#3a53b9",
        BLACK: "#000",
        RED_THEME: "#e61336",
        WHITE: "#FFF",
        GREEN: "#38d170",
        BLUE_OCEAN: "#06a6d6"
    }
}