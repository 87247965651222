import styled from 'styled-components'
import React from 'react'
import { Modal as RSModal } from 'rsuite'
import classNames from 'classnames'
import { FaPlus, FaPlusCircle } from 'react-icons/fa'
import Button from '../Buttons/Button'
import { TypeAttributes } from 'rsuite/esm/internals/types'

type IComponentProps = {
  onClose?: () => void
  isOpened?: boolean
  onSubmit?: () => void
  title?: string
  buttonLabel?: string
  size?: TypeAttributes.Size
  children: React.ReactNode
  loadding?: boolean
}
const Modal: React.FC<IComponentProps> = ({
  onClose,
  onSubmit,
  isOpened,
  title,
  children,
  buttonLabel,
  loadding = false,
  size
}) => {
  return (
    <RSModal
      backdrop="static"
      keyboard={false}
      open={isOpened}
      onClose={onClose}
      size={size}>
      <RSModal.Title className={classNames({ modalTitle: true })}>
        <Title>{title}</Title>
      </RSModal.Title>
      <RSModal.Body>{children}</RSModal.Body>
      <RSModal.Footer>
        <Button
          width="49"
          label={buttonLabel}
          color="#2b98e0"
          loading={loadding}
          onClick={onSubmit}
        />
        <Button
          width="49"
          label="CANCELAR"
          onClick={onClose}
          color="#e04f2b"
        />
      </RSModal.Footer>
    </RSModal>
  )
}
const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin: 2px;
  }
`
const Title = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`

export default Modal
