import { observer } from "mobx-react-lite";
import { Content } from "../style";
import { Title } from "../../../components/Typography";
import { Filter } from "../../../components/Inputs/Filter";
import { Col, FlexboxGrid } from "rsuite";
import Button from "../../../components/Buttons/Button";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useCourseStore } from "../../../stores/courseStore";
import { useCoursePresenter } from "./cursos/presenter";
import CourseTable from "../../../components/Tables/CourseTable";
import styled from "styled-components";
import GridCourse from "../../../components/Grid/GridCourse";
const options = [
    { value: "bySurname", label: "Mais novo" },
    { value: "byIdNumber", label: "Mais Antigo" },
    { value: "byAZ", label: "A-Z" },
    { value: "byZA", label: "Z-A" },
];
const Instrutor: React.FC = observer(() => {

    const navigate = useNavigate()
    const { resetCourseSaved } = useCourseStore();
    const { courseList } = useCoursePresenter()
    return (
        <Content>
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={12} as={Col} xs={24}>
                    <Title color="#000" style={{ marginTop: 10 }}>Meus cursos</Title>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12} as={Col} xs={24} style={{ display: "flex" }} >
                    <Filter options={options} />
                    <Button
                        label="Criar novo curso "
                        color={theme.BACKGROUND.BLUE}
                        onClick={() => {
                            resetCourseSaved()
                            navigate('/dashboard/instrutor/cursos/novo')
                        }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <ContentTable>
                {courseList && <GridCourse data={courseList} />}
            </ContentTable>
        </Content >
    );
});

export default Instrutor;
const ContentTable = styled.div`
  margin-top:20px
`