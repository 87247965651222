import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import styled from 'styled-components'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Section } from '../style'
import { Breadcrumb, Col, FlexboxGrid, Rate, Row, Tabs } from 'rsuite'
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { Drawers as Drawer, useUiStore } from '../../../stores/uiStore'
import { Drawers } from '../../../components'
import { Paragraph, ParagraphBold, Title } from '../../../components/Typography'
import { FaVolumeLow } from 'react-icons/fa6'
import Button from '../../../components/Buttons/Button'
import { Alert } from 'antd'
import { useAuthPresenter } from '../presenter'
const Checkout: React.FC = observer(() => {
    const { setDrawer } = useUiStore()
    const { getCourseId, course, getModulesByCourseId, moduleList, getQtdAula } = useCoursePresenter()
    const { id_course } = useParams();
    const { addPurchase, loading, resetStoreValuePurchased, setCourseId, purchasedSaved } = useAuthPresenter()
    const navigate = useNavigate()
    React.useEffect(() => {
        getCourseId(id_course as string);
        setCourseId(id_course as string)

    }, [])
    React.useEffect(() => {
        if (purchasedSaved) {
            navigate("/dashboard/user/meu-percurso")
            resetStoreValuePurchased()
        }
    }, [purchasedSaved])
    return (
        <><Section>
                {course &&
                    <Content>
                        <Row className="show-grid">
                            <Col xs={24} xl={14} xxl={24} md={24}>
                                <GridAboutCourse>
                                    <Alert message="Enquanto durar o tempo de preparação das condições técnicas para oferecer melhor experiência no processo de execução dos pagamentos dos cursos na Rozacademy, informamos que os pagamentos devem ser transferidos na conta com IBAN: 0040.0000.1563.6909.1019.2 em nome do Senhor Manuel da Ressureição Augusto Miranda .  " type="warning" showIcon  />
                                    <Title color='#000' style={{ marginTop: 10 }}>
                                        Detalhes do pedido
                                    </Title>
                                    <hr />
                                    <FlexboxGrid justify="space-between" style={{ marginTop: 10, marginBottom: 10, marginLeft: 0 }}>
                                        <FlexboxGrid.Item colspan={5}>
                                            <CardImgProfile>
                                                <img src={course.img_url} />
                                            </CardImgProfile>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={19}>
                                            <ParagraphBold size="14px" style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}>
                                                {course.titulo}
                                            </ParagraphBold>
                                            <Paragraph size="14px">
                                                <FaVolumeLow color='#999' style={{ marginRight: 10 }} /> Áudio: Português
                                            </Paragraph>
                                            <ParagraphBold color={theme.BACKGROUND.GREEN}>Gratuito</ParagraphBold>

                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </GridAboutCourse>
                            </Col>
                            <Col xs={24} xl={10} xxl={10} md={24}>
                                <GridDetailCourse id="infocourse">
                                    <ParagraphBold size="15px" style={{ marginBottom: 10, marginLeft:10 }}>
                                        Resumo
                                    </ParagraphBold>
                                    <hr style={{ padding:0,margin:0}}/>
                                    <Row className="show-grid">
                                        <Col xs={24}>
                                            <FlexboxGrid justify="space-between" style={{padding:10}}>
                                                <FlexboxGrid.Item colspan={19}>
                                                    <Paragraph>
                                                        Preço Original :
                                                    </Paragraph>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={5}>
                                                    <ParagraphBold size="14px" style={{ textAlign: "right" }} >
                                                        Gratuito
                                                    </ParagraphBold>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                            <hr style={{ padding:2,margin:0}}/>
                                        </Col>
                                    </Row>
                                    <Paragraph  style={{marginLeft:"4%"}}>
                                        <Button
                                            label='Finalizar  aquisição'
                                            width='96'
                                            color={theme.BACKGROUND.BLACK}
                                            onClick={() => {
                                                setDrawer(Drawer.PAYMENT_COURSE_DRAWER, true)
                                              }}
                                            loading={loading}
                                        />
                                    </Paragraph>
                                </GridDetailCourse>
                            </Col>
                        </Row>
                    </Content>
                }
            </Section >
            <Drawers.Payment />
        </>
    )
})
export default Checkout
interface IProps {
    backgroundImage?: string
}
const CardBreadcrumb = styled.div`
  width: 100%;height:50px;
  border-bottom:1px solid #e0e0e0;
  
`

const Content = styled.div`
  width: 76%;margin:auto;
  @media ${device.mobileL} {
    width:92%;margin:auto;padding:0
 }
`
const GridAboutCourse = styled.div`
  width: 100%;
  margin-top:10px;
  margin-bottom:10px;
  h1{
  font-family:Montserrat;
  }
`
const GridDetailCourse = styled.div`
  width: 100%;margin-bottom:10px;
  margin-top:5px;
 background-color:#fff;padding:10px 0px;
 border:1px solid #e0e0e0;
 border-radius:6px
`

const CardImgProfile = styled.div`
width:70px;
height:70px;
border:1px solid #e9e9e9;
border-radius:50%;
img{
width:60px;height:60px;margin:5px;
border-radius:50%;
}
  
`