import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import { H1, Paragraph, ParagraphBold, Subtitle } from '../../../components/Typography'
import styled from 'styled-components'
import { bg, instrutor, main } from '../../../images'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Modals as Modal, useUiStore } from '../../../stores/uiStore'
import { FaBriefcase, FaChalkboardTeacher, FaChartPie, FaDesktop, FaDraftingCompass, FaMoneyBillAlt, FaRocket, FaServer, FaUserGraduate, FaUserTie } from 'react-icons/fa'
import { ButtonPayCourse, CardCourse, CardImage, ContentCourse, ImageCardCourse, InfoCardCourse, Section } from '../style'
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Col, Rate, Row } from 'rsuite'
import { FaCartShopping } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
interface ICategorias {
    label?: string
    icon?: JSX.Element
    value?: number
    descript?: string
    color?: string
    path?: string
}
const categorias: ICategorias[] = [
    {
        label: 'Desenvolvimento & programação',
        value: 1,
        icon: <FaDesktop color='#e61336' size={24} style={{ marginTop: 5 }} />,
        descript: '',
        color: '#e61336',
        path: 'frontend'
    },
    {
        label: 'Acadêmico',
        value: 2,
        icon: <FaUserGraduate size={25} style={{ marginTop: 5 }} color='#f5d611' />,
        descript: '',
        color: '#14ba7d',
        path: 'backend'
    },
    {
        label: 'Administração Pública',
        value: 2,
        icon: <FaBriefcase size={24} style={{ marginTop: 5 }} color='#09e3b0' />,
        descript: '',
        color: '#14ba7d',
        path: 'backend'
    }, {
        label: 'Finanças & contabilidade',
        value: 2,
        icon: <FaChartPie color="#e36f09" size={24} style={{ marginTop: 5 }} />,
        descript: '',
        color: '#14ba7d',
        path: 'backend'
    }, {
        label: 'Produtividade no escritório',
        value: 2,
        icon: <FaRocket color="#55f511" size={24} style={{ marginTop: 5 }} />,
        descript: '',
        color: '#14ba7d',
        path: 'backend'
    }, {
        label: 'Desenvolvimento pessoal',
        value: 2,
        icon: <FaUserTie color="#11b1f5" size={24} style={{ marginTop: 5 }} />,
        descript: '',
        color: '#14ba7d',
        path: 'backend'
    }
]
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const Home: React.FC = observer(() => {
    const { courseList, getFormatCurrent } = useCoursePresenter();
    const navigate = useNavigate()
    return (
        <>
            <FrontLayout>
                <SectionMain>
                    <Content>
                    <Row className="show-grid" style={{padding:0, margin:0}}>
                    <Col lg={10} xl={14} xxl={14} md={24} sm={24} style={{padding:0, margin:0}}>
                    <ContainerMain>
                            <H1 color={theme.BACKGROUND.WHITE}>
                                Acelere cada etapa da sua carreira.
                            </H1>
                            <Paragraph size="25px" color="#FFF" style={{ marginTop: 15 }}>
                                Melhore tuas habilidades  e prepara-te para o
                                mercado de trabalho, de forma prática e descomplicada .
                            </Paragraph>
                            <UlCategoria>
                                {categorias.map((item, i) => (
                                    <CategoriaItem>
                                        <Paragraph>{item?.icon && item?.icon}</Paragraph>
                                        <Paragraph size="10px" color={"#000"} >
                                            <strong>  {item?.label}</strong>
                                        </Paragraph>
                                    </CategoriaItem>
                                ))}
                            </UlCategoria>
                        </ContainerMain>
                    </Col>
                    <Col lg={14} xl={10} xxl={10} md={24} sm={24} mdHidden style={{padding:0, margin:0}}>
                        <ImageMain src={main} />
                    </Col>
                    </Row>
                    </Content>
                </SectionMain>
                {courseList &&
                 <Section>
                    <ContentCourse>
                        <ParagraphBold size="25px">
                            Nossas principais sugestões para você.
                        </ParagraphBold>
                        <Paragraph style={{ marginBottom: 15 }} size="16px">Aproveite o aprendizado em casa sem um cronograma definido e com um método fácil de seguir. Você define seu próprio ritmo.</Paragraph>
                        <Carousel responsive={responsive} >
                            {courseList.map((item, i) => (
                                <CardCourse>
                                    <ImageCardCourse src={item?.img_url} />
                                    <InfoCardCourse>
                                        <Link to={`/cursos/${item?.id}/detail`} style={{ textDecoration: "none" }}>
                                            <ParagraphBold size="14px" style={{ marginRight: 10 }}>{item?.titulo}</ParagraphBold>
                                        </Link>
                                        <Paragraph>{item?.users?.name}</Paragraph>
                                        <Paragraph size="11px" >
                                            <strong>2.7</strong> <Rate defaultValue={4} size="xs" color="yellow" />(43)
                                        </Paragraph>
                                        <ParagraphBold color={theme.BACKGROUND.GREEN}>
                                            {item?.price ? 
                                           `${getFormatCurrent(item?.price as number)} Kz`
                                           :
                                            "Grátis"
                                            }
                                            
                                        </ParagraphBold>
                                        <ButtonPayCourse onClick={
                                            () => {
                                                navigate('/cursos/' + item?.id + '/detail')
                                            }
                                        }>
                                            <FaCartShopping size={14} /> Adquirir Agora
                                        </ButtonPayCourse>
                                    </InfoCardCourse>
                                </CardCourse>
                            ))}
                        </Carousel>
                    </ContentCourse>
                </Section>}
            </FrontLayout>
        </>
    )
})
export default Home
const SectionMain = styled.section`
  width: 100%;min-height:130px;
  background:url(${bg});
  background-position:100% 50%;
  justify-content: center;
`
const Content = styled.div`
  width: 100%;min-height:130px;
  background-color: rgba(0, 0, 0, 0.9);
 
`
const ContainerMain = styled.div`
    margin:auto;
    padding:50px;
    padding-right:0;
    padding-bottom:0;
    margin-top:15%;
    @media ${device.laptopL} {
        margin-top:12%
    }
    @media ${device.mobileL} {
        margin-top:0;
        padding:14px
    }
        
`

const ImageMain = styled.img`
width:100% 
`

const UlCategoria = styled.ul`
 list-style:none;
 display: flex;
 flex-direction: row;margin:0;padding:0;
 justify-content: flex-start ;
 flex-wrap: wrap;
 margin-top:20px;
 @media ${device.mobileL} {
    margin-top:10px
 }
`
const CategoriaItem = styled.li`
  background-color:${theme.BACKGROUND.WHITE};
  padding:15px 0;margin-right:1%;
  border-radius:10px;
  width:15%;
  height:70px;
  margin-top:10px;
  p{
  text-align:center;
  }
  @media ${device.mobileL} {
    width:32%;
  }
  @media ${device.laptopL} {
    width:20%;
    min-height:100px;
    p{
            
    }
  }
  

`