

export const usageData = [
  {
    value: 0,
    label: "Número de consultas este mês",
    num: 41,
    background: "#353535",
  }
];
interface ICategorias {
  label: string
  value: string
}
export const categoriasCurso: ICategorias[] = [
  {
    label: 'Desenvolvimento & programação',
    value: "1",
  },
  {
    label: 'Acadêmico',
    value: "2",
  },
  {
    label: 'Administração Pública',
    value: "3",
  }, {
    label: 'Finanças & contabilidade',
    value: "4"
  },
  {
    label: 'Produtividade no escritório',
    value: "5",
  },
  {
    label: 'Desenvolvimento pessoal',
    value: "6",
  },
  {
    label: 'Arquitetura',
    value: "7",
  },
]
export const levelCurso: ICategorias[] = [
  {
    label: 'Nível Iniciante',
    value: "1",
  },
  {
    label: 'Nível Intermediário',
    value: "2",
  },
  {
    label: 'Nível Especialista',
    value: "3",
  }, {
    label: 'Todos os níveis',
    value: "4"
  }
]