import { observer } from 'mobx-react-lite'
import { Route, Routes, useParams } from 'react-router-dom'
import { Container, Content } from '../../../style'
import ManageBasic from './basic'
import { Paragraph, ParagraphBold } from '../../../../../components/Typography'
import styled from 'styled-components'
import React, { useState } from 'react';
import ManagePrice from './price';
import { useNavigate } from "react-router-dom";
import ManageMessage from './message';
import ManageImage from './image';
import ManageGrade from './grade';
import { FaCircleInfo, FaListOl } from 'react-icons/fa6';
import type { MenuProps } from 'antd';
import { Layout, Menu, } from 'antd';
import Instrutor from './instrutor'
import { device } from '../../../../../config/devices'
const { Sider } = Layout;
const menu_options = [
    {
        icon: <FaCircleInfo />,
        label: "Informação básica do curso",
        children:
            [
                { key: "basic", label: "Página inicial " },
                { key: "price", label: "Preço " },
                { key: "message", label: "Mensagens " },
                { key: "image", label: "Imagem e vídeo promocional" },
                { key: "instrutor", label: "Instrutor " }
            ],
    }, {
        icon: <FaListOl />,
        label: "Grade curricular do curso",
        children:
            [
                { key: "grade", label: "Grade curricular " },
            ],
    }
];
const items2: MenuProps['items'] = menu_options.map(
    (item, index) => {
        const key = String(index + 1);
        return {
            key: `sub${key}`,
            icon: item.icon,
            label: <ParagraphBold size="13px">{item.label}</ParagraphBold>,

            children: item.children.map((item1, j) => {
                return {
                    key: item1.key,
                    label: <Paragraph size="13px">{item1.label}</Paragraph>,
                };
            }),
        };
    },
);
const Manage: React.FC = observer(() => {
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(true);
    const onClick: MenuProps['onClick'] = (e) => {
        navigate(e.key)
    };
    return (
        <>
            <Container>
                <Content>
                    <Layout
                    
                        style={{ padding: '24px 0', background: "#fff", border: "1px solid #e9e9e9" }}
                    >
                        <Sider 
                        style={{ background: "#FFF" }} 
                        trigger={null} collapsible collapsed={collapsed}
                        width={350}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                style={{ height: '100%' }}
                                items={items2}
                                onClick={onClick}
                            />
                        </Sider>
                        <Content style={{  minHeight: 280 }}>
                            <ManageBody>
                                <Routes>
                                    <Route path="basic" element={<ManageBasic />} />
                                    <Route path="price" element={<ManagePrice />} />
                                    <Route path="message" element={<ManageMessage />} />
                                    <Route path="image" element={<ManageImage />} />
                                    <Route path="grade" element={<ManageGrade />} />
                                </Routes>
                            </ManageBody>
                        </Content>
                    </Layout>

                </Content>
            </Container>

        </>

    )
})
const ManageBody = styled.div`
  @media ${device.mobileL} {
    padding:10px 10px
  }
`;
export default Manage
