import React, { ChangeEvent } from "react";

import TextInput from "./TextInput";
import Select, { components, ControlProps } from "react-select";
import { Filter as FilterIcon } from "react-feather";

type ComponentProps = {
  options: { value: string; label: string }[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <components.Control {...props}>
      <FilterIcon size={14} style={{ marginLeft: 10 }} />
      {children}
    </components.Control>
  );
};

export const Filter = ({ options, onChange }: ComponentProps) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Select
        options={options}
        placeholder="Filtrar"
        isSearchable={false}
        components={{ Control }}
        styles={{
          control: (provided: any) => ({
            ...provided,
            width: 250, height: 50, marginTop: 10,
            marginRight: 10, borderRadius: 0,
            textAlign: "left",
          }),
          menu: (provided: any) => ({
            ...provided,
            zIndex: 3,
            textAlign: "left",
          }),
        }}
      />

    </div>
  );
};
