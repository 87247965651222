import React from 'react'
import styled from 'styled-components'
import HeaderFront from '../components/Headers/HeaderFront'
import FrontFooter from '../components/Footers/FrontFrooter'
type IProps = {
  children: React.ReactNode // 👈️ type children
}
const FrontLayout = (props: IProps) => {
  return (
    <Container>
      <HeaderFront />
      {props.children}
      <FrontFooter />
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
`
export default FrontLayout
