import React from 'react'
import styled from 'styled-components'
import HeaderFront from '../components/Headers/HeaderFront'
type IProps = {
  children: React.ReactNode // 👈️ type children
}
const DashboardLayout = (props: IProps) => {
  return (
    <>
      <HeaderFront />
      {props.children}
    </>
  )
}


export default DashboardLayout
