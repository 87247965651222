import { observer } from 'mobx-react-lite'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import { Paragraph, ParagraphBold, Title } from '../../../../../components/Typography'
import TextInputLarge from '../../../../../components/Inputs/TextInputLarge'
import React from 'react'
import { useCoursePresenter } from '../presenter'
import Button from '../../../../../components/Buttons/Button'
import theme from '../../../../../theme'
const ManagePrice: React.FC = observer(() => {
    const { getCourseId, course, getFormatCurrent, setFormPrice, loadding, update } = useCoursePresenter()
    let { id_course } = useParams();
    React.useEffect(() => {
        getCourseId(id_course as string);
    }, [])
    return (
        <>

            <Title color='#000' style={{ margin: 0, marginLeft: 0 }}>{course?.titulo}</Title>
            <Paragraph  style={{  marginTop: 10 }} size="18px" color="#888">Preço</Paragraph>
            <hr />
            <Paragraph size="13">
                A informação de preço do seu curso é essencial para o seu sucesso na <Link to={""}><strong>Rosa Academy</strong></Link>.
                Se você quiser oferecer seu curso gratuitamente, ele deve ter uma duração total de vídeo inferior a 2 horas.
            </Paragraph>
            <ParagraphBold>Preço do curso</ParagraphBold>
            { course ?
             <TextInputLarge
             name='price'
             type='text'
             onChange={setFormPrice}
             defaultValue={course?.price ?`${getFormatCurrent(course?.price as number)}`: "0"}
             placeholder='Escrever preço do curso :'
             icon={<FaRegMoneyBillAlt size={20} style={{ margin: 12 }} />}
            />
             :
            <></>
            }
           
            <Paragraph size="11px">No caso de desejares um curso gratuito, por favor defina o preço a <strong>0</strong> .</Paragraph>

            <Button 
            label='Salvar informações do curso' 
            width='50' 
            loading={loadding}
            onClick={update}
            color={theme.BACKGROUND.BLUE} />
        </>
    )
})

export default ManagePrice
