import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import SupabaseGateway from '../gateways/SupabaseGateway'
import { Tables } from '../types/enums'
import { useClassStore } from '../util/useClassStore'
import container from './ioc'
import UIStore, {Drawers as Drawer,  AlertType } from './uiStore'
import {useNavigate } from 'react-router-dom'

@injectable()
class PurchasedCourseStore {
    @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway
    @inject(UIStore) ui!: UIStore
    purchasedList!: IPurchasedCourse[]
    constructor() {
        makeAutoObservable(this)
    }
    addPurchased = async (purchased: IPurchasedCourse) => {
        try {
            const { error, data } = await this.supabaseGateway.insertToTable<IPurchasedCourse>(
                Tables.purchased_courses,
                purchased
            )
            await this.getPurchasedByUser(purchased.id_user as string)
        } catch (error: any) {
            this.ui.showAlert(error.message, AlertType.error)
        }
    }
    getPurchasedByUser = async (id: string) => {
        try {
            const { data, error } =
                await this.supabaseGateway.selectFromTableWithFilter(
                    Tables.purchased_courses,
                    `*, cursos(*)`,
                    { column: 'id_user', operator: 'eq', value: id }
                )
            runInAction(() => {
                this.purchasedList = data as IPurchasedCourse[]
            })
            if (error) throw new Error(error.message)
            return data
        } catch (error: any) {
            this.ui.showAlert(error.message, AlertType.error)
        }
    }
    uploadComprovativoPayment = async (image: File, purchased: IPurchasedCourse) => {
        try {
    
          const { publicUrl } = await this.supabaseGateway.uploadFile<IPurchasedCourse>(
            image,
            "ficheiros"
          )
            purchased.file = publicUrl;
            const { error, data } = await this.supabaseGateway.insertToTable<IPurchasedCourse>(
            Tables.purchased_courses,
            purchased
            )
            await this.getPurchasedByUser(purchased.id_user as string)
            this.ui.showAlert('pagamento finalizado com sucesso...', AlertType.success)
            this.ui.setDrawer(Drawer.PAYMENT_COURSE_DRAWER, false)
        } catch (error: any) {
            this.ui.showAlert(error.message, AlertType.error); 
        }
      }
    }
export const useUPurshasedCourseStore = () =>
    useClassStore<PurchasedCourseStore>(container.get(PurchasedCourseStore))

export default PurchasedCourseStore
