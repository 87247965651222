import { observer } from 'mobx-react-lite'
import FrontLayout from '../../../layouts/FrontLayout'
import { H1, Paragraph, ParagraphBold} from '../../../components/Typography'
import styled from 'styled-components'
import { bg, bg10, bg11, bg8, bg9, instrutor, main } from '../../../images'
import theme from '../../../theme'
import { device } from '../../../config/devices'
import { Modals as Modal, useUiStore } from '../../../stores/uiStore'
import { FaBriefcase, FaChalkboardTeacher, FaChartPie, FaDesktop, FaDraftingCompass, FaMoneyBillAlt, FaRocket, FaServer, FaUserGraduate, FaUserTie } from 'react-icons/fa'
import { ButtonPayCourse, CardCourse, CardImage, ContentCourse, ImageCardCourse, InfoCardCourse, Section } from '../style'
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from '../../dashboard/instrutor/cursos/presenter'
import { Col, Divider, Grid, Rate, Row } from 'rsuite'
import { FaCartShopping, FaCheck, FaComputer, FaPlay } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/Buttons/Button'

const TrainingCenter: React.FC = observer(() => {
    const { courseList } = useCoursePresenter();
    const navigate = useNavigate()
    return (
        <>
            <FrontLayout>
                <Section style={{margin:0, padding:0}}>
                    <Row>
                         <Col lg={12} xl={12} xxl={10} md={24} sm={24}  xs={24} xsHidden>
                        <ImageMain backgroundImage={bg9}/>
                        </Col>
                        <Col lg={12} xl={12} xxl={10} md={24} sm={24} xs={23}>
                           <TextMargin>
                            <Title  color='#333'>
                                Digitalize seus  cursos e veja como 
                                centros de formação e treinamento  inovadores estão usando a Rosa Academy .
                             </Title>
                             <Paragraph size="20px" style={{marginTop:15}}>
                             Capacite seus funcionários para se destacarem na economia digital
                             </Paragraph>
                             <Paragraph size="18px">
                                <FaCheck color={theme.BACKGROUND.BLUE}/> Vídeos da mais alta qualidade, para que você não perca nenhum detalhe e com acesso ilimitado;
                             </Paragraph>
                             <Paragraph size="18px">
                                <FaCheck color={theme.BACKGROUND.BLUE}/> Conecte-se com uma comunidade criativa global;
                             </Paragraph>
                             <Paragraph size="18px">
                                <FaCheck color={theme.BACKGROUND.BLUE}/> Compartilhe conhecimentos e ideias;
                             </Paragraph>
                            <Button label='FALE CONOSCO' width='100' color={theme.BACKGROUND.BLUE}/>
                            </TextMargin>
                        </Col>
                    </Row>
                </Section>
                <Section style={{backgroundColor:"#fff"}}>  
                 <Grid fluid >
                 <Row className="show-grid">
                <Col lg={12} xl={12} xxl={24} md={24} sm={24} xs={24} style={{textAlign:"left"}}>
                   <TextMargin>
                        <Title style={{lineHeight:1}}>
                        Uma rede de alunos e docentes profissionais
                        com quem você pode compartilhar conhecimento.
                        </Title>
                        <Paragraph size="20px">
                        Ofereça experiências de aprendizagem práticas e relevantes para 
                        o trabalho com conteúdo profissional 
                        e cursos  ministrados por especialistas.
                        </Paragraph>
                    </TextMargin> 
                    </Col>
                    <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                    <PlayVideo>
                        <br/>
                        <PlayVideoButton>
                            <FaPlay color='#fff' size={20} style={{margin:"auto"}}/>
                        </PlayVideoButton>
                    </PlayVideo>

                    </Col>
                </Row>
                </Grid>
                </Section>
                <Section >
                <Grid fluid style={{padding:30}}>
                <Row className="show-grid">
                    <Col lg={12} xl={12} xxl={10} md={24} sm={24} style={{textAlign:"center"}}>
                        <Image src={bg8}/>
                    </Col>
                    <Col lg={12} xl={12} xxl={10} md={24} sm={24}  style={{textAlign:"left"}}>
                        <Title>
                        Ofereça caminhos de desenvolvimento de competências selecionados, para que haja progressão pelos níveis Iniciante, Intermediário e Avançado.
                        </Title>
                        <Paragraph size="17px" style={{marginTop:10}}>
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Fortaleça a empregabilidade na Rosa Academy ;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Explore uma variedade de recursos disponível para  impulsionar sua instituição;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ajude seus estudantes a aumentar a confiança no trabalho, aplicar o aprendido e aprimorar competências;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Ofereça aos estudantes projetos práticos para treinar competências e se destacar diante dos empregadores;
                        </Paragraph>
                        <Paragraph size="17px">
                            <FaCheck color={theme.BACKGROUND.BLUE}/> Melhore as experiências de aprendizagem;
                        </Paragraph>
                    </Col>
                </Row>
                </Grid>
                </Section>
            </FrontLayout>
        </>
    )
})
export default TrainingCenter;
interface IProps {
    backgroundImage?: string
}
export const ImageMain = styled.div<IProps>`
  width:100%;
  min-height:470px;
  background: url(${({ backgroundImage }) => (backgroundImage ? backgroundImage : "#F4F4F4")});
  background-size: cover;
`
export const PlayVideo = styled.div<IProps>`
  width:100%;
  min-height:350px;
  background-color:#f4f4f4 ;
  @media ${device.mobileL} {
    min-height:200px;
  }
`
export const PlayVideoButton = styled.div<IProps>`
  width:60px;height:60px;
  background-color:${theme.BACKGROUND.BLUE};
  margin:auto;margin-top:40px;
  border-radius:50%;
`
export const TextMargin = styled.div`
@media ${device.mobileL} {
    margin:10px
}
`

export const Image = styled.img<IProps>`
width:100%;
`
export const Title = styled.h5<IProps>`
  font-family:Montserrat;
  margin-top:10px;
  color: ${({ color }) => (color ? color : "#000")};
  font-size: 40px;
  line-height: 100%;
  @media ${device.mobileL} {
    font-size:18px
  }
`;